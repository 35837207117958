import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_PRESCRIPTION_LIST,
  SUCCESS_PRESCRIPTION_LIST,
  ERROR_PRESCRIPTION_LIST,
  START_CREATE_PRESCRIPTION_MANAGEMENT,
  SUCCESS_CREATE_PRESCRIPTION_MANAGEMENT,
  ERROR_CREATE_PRESCRIPTION_MANAGEMENT,
  START_DELETE_PRESCRIPTION_MANAGEMENT,
  SUCCESS_DELETE_PRESCRIPTION_MANAGEMENT,
  ERROR_DELETE_PRESCRIPTION_MANAGEMENT,
  START_UPDATE_PRESCRIPTION_MANAGEMENT,
  SUCCESS_UPDATE_PRESCRIPTION_MANAGEMENT,
  ERROR_UPDATE_PRESCRIPTION_MANAGEMENT,
  GET_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
  SUCCESS_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
  ERROR_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
  GET_PRESCRIPTION_HOW_MUCH_LIST,
  SUCCESS_PRESCRIPTION_HOW_MUCH_LIST,
  ERROR_PRESCRIPTION_HOW_MUCH_LIST,
  GET_PATIENT_NAME_LIST,
  SUCCESS_PATIENT_NAME_LIST,
  ERROR_PATIENT_NAME_LIST,
  GET_DRUG_LIST,
  SUCCESS_DRUG_LIST,
  ERROR_DRUG_LIST,
  GET_SIGN_LINK,
  SUCCESS_SIGN_LINK,
  ERROR_SIGN_LINK,
  GET_DOWNLOAD_ZOHO_SIGN_LINK,
  SUCCESS_DOWNLOAD_ZOHO_SIGN_LINK,
  ERROR_DOWNLOAD_ZOHO_SIGN_LINK,
  START_CREATE_ZOHO_SIGNATURE,
  SUCCESS_CREATE_ZOHO_SIGNATURE,
  ERROR_CREATE_ZOHO_SIGNATURE,
} from "../Action/PrescriptionAction";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";
import {
  getPrescriptionListAPI,
  createPrescriptionManagementAPI,
  deletePrescriptionManagementAPI,
  updatePrescriptionManagementAPI,
  getSingleLiPrescriptionManagementByIdAPI,
  getPrescriptionHowMuchListAPI,
  getPatientNameListAPI,
  getDrugListAPI,
  getDownloadPdfAPI,
  getSignatureAPI,
  getCreateSignatureAPI,
} from "../Api/PrescriptionApis";

function* getPrescriptionListWorker({ payload }) {
  try {
    const res = yield call(getPrescriptionListAPI, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_PRESCRIPTION_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PRESCRIPTION_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createPrescriptionWorker({ payload }) {
  try {
    const res = yield call(createPrescriptionManagementAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_PRESCRIPTION_MANAGEMENT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_PRESCRIPTION_MANAGEMENT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deletePrescriptionWorker({ id }) {
  try {
    const res = yield call(deletePrescriptionManagementAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_DELETE_PRESCRIPTION_MANAGEMENT,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Prescription deleted successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_PRESCRIPTION_MANAGEMENT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.errors.id[0],
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updatePrescriptionWorker({ id, payload }) {
  try {
    const res = yield call(updatePrescriptionManagementAPI, id, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_UPDATE_PRESCRIPTION_MANAGEMENT,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Prescription updated successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_PRESCRIPTION_MANAGEMENT,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSinglePrescriptionListGetByIdWorker({ id }) {
  try {
    const res = yield call(getSingleLiPrescriptionManagementByIdAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getPrescriptionHowMuchWorker({ payload }) {
  try {
    const res = yield call(getPrescriptionHowMuchListAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_PRESCRIPTION_HOW_MUCH_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PRESCRIPTION_HOW_MUCH_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getPatientNameListWorker({ payload }) {
  try {
    const res = yield call(getPatientNameListAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_PATIENT_NAME_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PATIENT_NAME_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getDrugListWorker({ payload }) {
  try {
    const res = yield call(getDrugListAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_DRUG_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_DRUG_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSignLinkWorker({ payload }) {
  try {
    const res = yield call(getSignatureAPI, payload);
    if (res.data.success) {
      yield put({
        type: SUCCESS_SIGN_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SIGN_LINK,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getDownloadBoldSignPdfWorker({ payload }) {
  try {
    const res = yield call(getDownloadPdfAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_DOWNLOAD_ZOHO_SIGN_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_DOWNLOAD_ZOHO_SIGN_LINK,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getCreateSignatureWorker({ payload }) {
  try {
    const res = yield call(getCreateSignatureAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_ZOHO_SIGNATURE,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CREATE_ZOHO_SIGNATURE,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* PrescriptionWatcher() {
  yield takeEvery(GET_PRESCRIPTION_LIST, getPrescriptionListWorker);
  yield takeEvery(
    START_CREATE_PRESCRIPTION_MANAGEMENT,
    createPrescriptionWorker
  );
  yield takeEvery(
    START_DELETE_PRESCRIPTION_MANAGEMENT,
    deletePrescriptionWorker
  );
  yield takeEvery(
    START_UPDATE_PRESCRIPTION_MANAGEMENT,
    updatePrescriptionWorker
  );
  yield takeEvery(
    GET_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
    getSinglePrescriptionListGetByIdWorker
  );
  yield takeEvery(GET_PRESCRIPTION_HOW_MUCH_LIST, getPrescriptionHowMuchWorker);
  yield takeEvery(GET_PATIENT_NAME_LIST, getPatientNameListWorker);
  yield takeEvery(GET_DRUG_LIST, getDrugListWorker);
  yield takeEvery(GET_SIGN_LINK, getSignLinkWorker);
  yield takeEvery(GET_DOWNLOAD_ZOHO_SIGN_LINK, getDownloadBoldSignPdfWorker);
  yield takeEvery(START_CREATE_ZOHO_SIGNATURE, getCreateSignatureWorker);
}
