import API from "../Api";
import {
  GET_USER_DETAIL_ENDPOINT,
  GET_AFTER_LOGIN_USER_DETAIL_ENDPOINT,
} from "./endpoints";

//Get User Detail
export const getUserDetailAPI = (id) => {
  return API.get(`${GET_USER_DETAIL_ENDPOINT}?id=${id}`);
};

//After Login Get User Detail
export const getAfterLoginUserDetailAPI = () => {
  return API.get(GET_AFTER_LOGIN_USER_DETAIL_ENDPOINT);
};
