import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_USER_DETAIL,
  SUCCESS_USER_DETAIL,
  ERROR_USER_DETAIL,
  GET_AFTER_LOGIN_USER_DETAIL,
  SUCCESS_AFTER_LOGIN_USER_DETAIL,
  ERROR_AFTER_LOGIN_USER_DETAIL,
} from "../Action/UserAction";
import { getUserDetailAPI, getAfterLoginUserDetailAPI } from "../Api/UserApis";

function* getUserDetailWorker({ id }) {
  try {
    const res = yield call(getUserDetailAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_USER_DETAIL,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_USER_DETAIL,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getAfterLoginUserDetailWorker() {
  try {
    const res = yield call(getAfterLoginUserDetailAPI);
    if (res?.success) {
      yield put({
        type: SUCCESS_AFTER_LOGIN_USER_DETAIL,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_AFTER_LOGIN_USER_DETAIL,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* UserWatcher() {
  yield takeEvery(GET_USER_DETAIL, getUserDetailWorker);
  yield takeEvery(GET_AFTER_LOGIN_USER_DETAIL, getAfterLoginUserDetailWorker);
}
