import {
  GET_PRESCRIPTION_LIST,
  SUCCESS_PRESCRIPTION_LIST,
  ERROR_PRESCRIPTION_LIST,
  START_CREATE_PRESCRIPTION_MANAGEMENT,
  SUCCESS_CREATE_PRESCRIPTION_MANAGEMENT,
  ERROR_CREATE_PRESCRIPTION_MANAGEMENT,
  START_DELETE_PRESCRIPTION_MANAGEMENT,
  SUCCESS_DELETE_PRESCRIPTION_MANAGEMENT,
  ERROR_DELETE_PRESCRIPTION_MANAGEMENT,
  START_UPDATE_PRESCRIPTION_MANAGEMENT,
  SUCCESS_UPDATE_PRESCRIPTION_MANAGEMENT,
  ERROR_UPDATE_PRESCRIPTION_MANAGEMENT,
  GET_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
  SUCCESS_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
  ERROR_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID,
  RESET_PRESCRIPTION_MANAGEMENT,
  GET_PRESCRIPTION_HOW_MUCH_LIST,
  SUCCESS_PRESCRIPTION_HOW_MUCH_LIST,
  ERROR_PRESCRIPTION_HOW_MUCH_LIST,
  GET_PATIENT_NAME_LIST,
  SUCCESS_PATIENT_NAME_LIST,
  ERROR_PATIENT_NAME_LIST,
  GET_DRUG_LIST,
  SUCCESS_DRUG_LIST,
  ERROR_DRUG_LIST,
  GET_SIGN_LINK,
  SUCCESS_SIGN_LINK,
  ERROR_SIGN_LINK,
  GET_DOWNLOAD_ZOHO_SIGN_LINK,
  SUCCESS_DOWNLOAD_ZOHO_SIGN_LINK,
  ERROR_DOWNLOAD_ZOHO_SIGN_LINK,
  START_CREATE_ZOHO_SIGNATURE,
  SUCCESS_CREATE_ZOHO_SIGNATURE,
  ERROR_CREATE_ZOHO_SIGNATURE,
} from "../Action/PrescriptionAction";

const initialState = {
  getPrescriptionList: {
    loading: false,
    data: {},
    errors: {},
  },
  createPrescriptionManagementData: {
    loading: false,
    data: [],
    error: {},
  },
  deletePrescriptionManagementData: {
    loading: false,
    data: {},
    error: {},
  },
  updatePrescriptionManagementData: {
    loading: false,
    data: {},
    error: {},
  },
  singlePrescriptionManagementData: {
    loading: false,
    data: {},
    error: {},
  },
  getPrescriptionHowMuchList: {
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  getPatientNameList: {
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  getDrugList: {
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  getSignLinkDetails: {
    loading: false,
    data: {},
    error: {},
  },
  geDownloadZohoSignPdfDetails: {
    loading: false,
    data: {},
    error: {},
  },
  getPrescriptionViewDetails: {
    loading: false,
    data: {},
    errors: {},
  },
  getSendDocumentForSignatureDetails: {
    loading: false,
    data: {},
    errors: {},
  },
  createSignature: {
    loading: false,
    data: {},
    errors: {},
  },
};

const PrescriptionReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action.key],
      };
    }
    case GET_PRESCRIPTION_LIST: {
      let oldList = state?.getPrescriptionList?.data;
      return {
        ...state,
        getPrescriptionList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_PRESCRIPTION_LIST: {
      let oldList = state?.getPrescriptionList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getPrescriptionList: {
          loading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_PRESCRIPTION_LIST: {
      return {
        ...state,
        getPrescriptionList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        createPrescriptionManagementData: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_CREATE_PRESCRIPTION_MANAGEMENT: {
      let oldList = state?.getPrescriptionList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [action?.payload?.data, ...oldList?.list];
        oldList.filtered_record = oldList?.filtered_record + 1;
      }
      return {
        ...state,
        createPrescriptionManagementData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_CREATE_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        createPrescriptionManagementData: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case START_DELETE_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        deletePrescriptionManagementData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_DELETE_PRESCRIPTION_MANAGEMENT: {
      let oldList = state?.getPrescriptionList?.data;
      oldList.list = oldList?.list?.filter((item) => item?.id !== action?.id);
      oldList.filtered_record = oldList?.filtered_record - 1;
      return {
        ...state,
        deletePrescriptionManagementData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_DELETE_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        deletePrescriptionManagementData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        updatePrescriptionManagementData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_UPDATE_PRESCRIPTION_MANAGEMENT: {
      let oldList = state?.getPrescriptionList?.data;
      oldList.list = oldList?.list?.map((item) => {
        if (item?.id === action?.id) {
          return {
            ...item,
            ...action?.payload?.data,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        updatePrescriptionManagementData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_PRESCRIPTION_MANAGEMENT: {
      return {
        ...state,
        updatePrescriptionManagementData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID: {
      let oldList = state?.singlePrescriptionManagementData?.data;
      return {
        ...state,
        singlePrescriptionManagementData: {
          loading: true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID: {
      let oldList = state?.singlePrescriptionManagementData?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        singlePrescriptionManagementData: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID: {
      return {
        ...state,
        singlePrescriptionManagementData: {
          loading: false,
          data: {},
          error: {},
        },
      };
    }
    case GET_PRESCRIPTION_HOW_MUCH_LIST: {
      let oldList = state?.getPrescriptionHowMuchList?.data;
      return {
        ...state,
        getPrescriptionHowMuchList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_PRESCRIPTION_HOW_MUCH_LIST: {
      let oldList = state?.getPrescriptionHowMuchList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getPrescriptionHowMuchList: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_PRESCRIPTION_HOW_MUCH_LIST: {
      return {
        ...state,
        reportingPersonList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_PATIENT_NAME_LIST: {
      let oldList = state?.getPatientNameList?.data;
      return {
        ...state,
        getPatientNameList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_PATIENT_NAME_LIST: {
      let oldList = state?.getPatientNameList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getPatientNameList: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_PATIENT_NAME_LIST: {
      return {
        ...state,
        getPatientNameList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_DRUG_LIST: {
      let oldList = state?.getDrugList?.data;
      return {
        ...state,
        getDrugList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_DRUG_LIST: {
      let oldList = state?.getDrugList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getDrugList: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_DRUG_LIST: {
      return {
        ...state,
        getDrugList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SIGN_LINK: {
      return {
        ...state,
        getSignLinkDetails: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_SIGN_LINK: {
      return {
        ...state,
        getSignLinkDetails: {
          loading: false,
          data: action?.payload?.data,
          error: {},
        },
      };
    }
    case ERROR_SIGN_LINK: {
      return {
        ...state,
        getSignLinkDetails: {
          loading: false,
          data: {},
          error: {},
        },
      };
    }
    case GET_DOWNLOAD_ZOHO_SIGN_LINK: {
      return {
        ...state,
        geDownloadZohoSignPdfDetails: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_DOWNLOAD_ZOHO_SIGN_LINK: {
      return {
        ...state,
        geDownloadZohoSignPdfDetails: {
          loading: false,
          data: action?.payload,
          error: {},
        },
      };
    }
    case ERROR_DOWNLOAD_ZOHO_SIGN_LINK: {
      return {
        ...state,
        geDownloadZohoSignPdfDetails: {
          loading: false,
          data: {},
          error: action.payload.data,
        },
      };
    }
    case START_CREATE_ZOHO_SIGNATURE: {
      return {
        ...state,
        createSignature: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_CREATE_ZOHO_SIGNATURE: {
      return {
        ...state,
        createSignature: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_CREATE_ZOHO_SIGNATURE: {
      return {
        ...state,
        createSignature: {
          loading: false,
          data: {},
          error: action.payload.data,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default PrescriptionReducers;
