import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_PATIENT_MANAGEMENT_LIST,
  SUCCESS_PATIENT_MANAGEMENT_LIST,
  ERROR_PATIENT_MANAGEMENT_LIST,
  START_CREATE_PATIENT_MANAGEMENT,
  SUCCESS_CREATE_PATIENT_MANAGEMENT,
  ERROR_CREATE_PATIENT_MANAGEMENT,
  START_DELETE_PATIENT_MANAGEMENT,
  SUCCESS_DELETE_PATIENT_MANAGEMENT,
  ERROR_DELETE_PATIENT_MANAGEMENT,
  START_UPDATE_PATIENT_MANAGEMENT,
  SUCCESS_UPDATE_PATIENT_MANAGEMENT,
  ERROR_UPDATE_PATIENT_MANAGEMENT,
  GET_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
  SUCCESS_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
  ERROR_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
} from "../Action/PatientManagementAction";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";
import {
  getPatientManagementListAPI,
  createPatientManagementAPI,
  deletePatientManagementAPI,
  updatePatientManagementAPI,
  getSingleLiPatientManagementByIdAPI,
} from "../Api/PatientManagementApis";

function* getPatientManagementListWorker({ payload }) {
  try {
    const res = yield call(getPatientManagementListAPI, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_PATIENT_MANAGEMENT_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PATIENT_MANAGEMENT_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createPatientManagementWorker({ formData }) {
  try {
    const res = yield call(createPatientManagementAPI, formData);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_PATIENT_MANAGEMENT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_PATIENT_MANAGEMENT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.data.errors[0] || res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* patientManagementDeleteWorker({ id }) {
  try {
    const res = yield call(deletePatientManagementAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_DELETE_PATIENT_MANAGEMENT,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Patient deleted successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_PATIENT_MANAGEMENT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.errors.id[0],
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updatePatientManagementWorker({ id, payload }) {
  try {
    const res = yield call(updatePatientManagementAPI, id, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_UPDATE_PATIENT_MANAGEMENT,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Patient updated successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_PATIENT_MANAGEMENT,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSinglePatientManagementListGetByIdWorker({ id }) {
  try {
    const res = yield call(getSingleLiPatientManagementByIdAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* PatientManagementWatcher() {
  yield takeEvery(GET_PATIENT_MANAGEMENT_LIST, getPatientManagementListWorker);
  yield takeEvery(
    START_DELETE_PATIENT_MANAGEMENT,
    patientManagementDeleteWorker
  );
  yield takeEvery(
    START_UPDATE_PATIENT_MANAGEMENT,
    updatePatientManagementWorker
  );
  yield takeEvery(
    START_CREATE_PATIENT_MANAGEMENT,
    createPatientManagementWorker
  );
  yield takeEvery(
    GET_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
    getSinglePatientManagementListGetByIdWorker
  );
}
