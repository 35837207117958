import API from "../Api";
import {
  VERIFY_PICKUP_CODE_ENDPOINT,
  CREATE_PHARMACY_DETAILS_ENDPOINT,
} from "./endpoints";

//Verify Pickup Code
export const verifyPickupOptionAPI = (payload) => {
  return API.post(VERIFY_PICKUP_CODE_ENDPOINT, payload);
};

//Pharmacy Details
export const pharmacyDetailsAPI = (payload) => {
  return API.post(CREATE_PHARMACY_DETAILS_ENDPOINT, payload);
};
