import {
  GET_PHARMA_STORE_LIST,
  SUCCESS_PHARMA_STORE_LIST,
  ERROR_PHARMA_STORE_LIST,
  GET_UPDATE_ORDER_STATUS,
  SUCCESS_UPDATE_ORDER_STATUS,
  ERROR_UPDATE_ORDER_STATUS,
  RESET_PHARMA_STORE,
} from "../Action/PharmaStoreAction";

const initialState = {
  getPharmaStoreList: {
    loading: false,
    data: {},
    error: {},
  },
  updateOrderStatusList: {
    isLoading: false,
    data: {},
    errors: {},
  },
};

const PharmaStoreReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PHARMA_STORE: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action.key],
      };
    }
    case GET_PHARMA_STORE_LIST: {
      let oldList = state?.getPharmaStoreList?.data;
      return {
        ...state,
        getPharmaStoreList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_PHARMA_STORE_LIST: {
      let oldList = state?.getPharmaStoreList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getPharmaStoreList: {
          loading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_PHARMA_STORE_LIST: {
      return {
        ...state,
        getPharmaStoreList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_UPDATE_ORDER_STATUS: {
      return {
        ...state,
        updateOrderStatusList: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_UPDATE_ORDER_STATUS: {
      return {
        ...state,
        updateOrderStatusList: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_ORDER_STATUS: {
      return {
        ...state,
        updateOrderStatusList: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default PharmaStoreReducers;
