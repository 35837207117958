//Get Patient View List
export const GET_PATIENT_VIEW_DETAILS = "GET_PATIENT_VIEW_DETAILS";
export const SUCCESS_PATIENT_VIEW_DETAILS = "SUCCESS_PATIENT_VIEW_DETAILS";
export const ERROR_PATIENT_VIEW_DETAILS = "ERROR_PATIENT_VIEW_DETAILS";

//Create Delivery Address With Payment
export const START_CREATE_DELIVER_ADDRESS = "START_CREATE_DELIVER_ADDRESS";
export const SUCCESS_CREATE_DELIVER_ADDRESS = "SUCCESS_CREATE_DELIVER_ADDRESS";
export const ERROR_CREATE_DELIVER_ADDRESS = "ERROR_CREATE_DELIVER_ADDRESS";

//Update Delivery Address
export const START_UPDATE_DELIVER_ADDRESS = "START_UPDATE_DELIVER_ADDRESS";
export const SUCCESS_UPDATE_DELIVER_ADDRESS = "SUCCESS_UPDATE_DELIVER_ADDRESS";
export const ERROR_UPDATE_DELIVER_ADDRESS = "ERROR_UPDATE_DELIVER_ADDRESS";

//Single Delivery Address By Id
export const GET_SINGLE_DELIVER_ADDRESS_BY_ID =
  "GET_SINGLE_DELIVER_ADDRESS_BY_ID";
export const SUCCESS_SINGLE_DELIVER_ADDRESS_BY_ID =
  "SUCCESS_SINGLE_DELIVER_ADDRESS_BY_ID";
export const ERROR_SINGLE_DELIVER_ADDRESS_BY_ID =
  "ERROR_SINGLE_DELIVER_ADDRESS_BY_ID";

//Delete Delivery Address
export const START_DELETE_DELIVER_ADDRESS = "START_DELETE_DELIVER_ADDRESS";
export const SUCCESS_DELETE_DELIVER_ADDRESS = "SUCCESS_DELETE_DELIVER_ADDRESS";
export const ERROR_DELETE_DELIVER_ADDRESS = "ERROR_DELETE_DELIVER_ADDRESS";

//Reset Reducer State
export const RESET_DELIVER_ADDRESS = "RESET_DELIVER_ADDRESS";

//Get Address Details
export const GET_PATIENT_ADDRESS_DETAILS = "GET_PATIENT_ADDRESS_DETAILS";
export const SUCCESS_PATIENT_ADDRESS_DETAILS =
  "SUCCESS_PATIENT_ADDRESS_DETAILS";
export const ERROR_PATIENT_ADDRESS_DETAILS = "ERROR_PATIENT_ADDRESS_DETAILS";

//Payment Method
export const START_PAYMENT_METHOD = "START_PAYMENT_METHOD";
export const SUCCESS_PAYMENT_METHOD = "SUCCESS_PAYMENT_METHOD";
export const ERROR_PAYMENT_METHOD = "ERROR_PAYMENT_METHOD";

//Get Pick up code
export const GET_PICKUP_CODE = "GET_PICKUP_CODE";
export const SUCCESS_PICKUP_CODE = "SUCCESS_PICKUP_CODE";
export const ERROR_PICKUP_CODE = "ERROR_PICKUP_CODE";

//Set Pick Option
export const SET_PICKUP_OPTION_CODE = "SET_PICKUP_OPTION_CODE";
export const SUCCESS_PICKUP_OPTION_CODE = "SUCCESS_PICKUP_OPTION_CODE";
export const ERROR_PICKUP_OPTION_CODE = "ERROR_PICKUP_OPTION_CODE";
