import API from "../Api";
import {
  GET_PRESCRIPTION_LIST_ENDPOINT,
  CREATE_PRESCRIPTION_ENDPOINT,
  DELETE_PRESCRIPTION_ENDPOINT,
  UPDATE_PRESCRIPTION_ENDPOINT,
  GET_PATIENT_NAME_LIST_ENDPOINT,
  GET_DRUG_LIST_ENDPOINT,
  GET_PRESCRIPTION_HOW_MUCH_LIST_ENDPOINT,
  SINGLE_PRESCRIPTION_LIST_GET_BY_ID_ENDPOINT,
  GET_SIGN_LINK_ENDPOINT,
  GET_DOWNLOAD_ZOHO_SIGN_PDF_ENDPOINT,
  GET_SIGNATURE_ENDPOINT,
  CREATE_SIGNATURE_ENDPOINT,
} from "./endpoints";
import { generateDynamicEndpoint } from "../utils/generalUtils";

export const getPrescriptionListAPI = (payload) => {
  return API.post(GET_PRESCRIPTION_LIST_ENDPOINT, payload);
};

//Create Prescription Management
export function createPrescriptionManagementAPI(payload) {
  return API.post(CREATE_PRESCRIPTION_ENDPOINT, payload);
}

//Delete Prescription Management
export const deletePrescriptionManagementAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_PRESCRIPTION_ENDPOINT, { id: id })
  );
};

//Update Prescription Management
export const updatePrescriptionManagementAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(UPDATE_PRESCRIPTION_ENDPOINT, { id: id }),
    payload
  );
};

//Get Single Prescription Management LIst By Id
export const getSingleLiPrescriptionManagementByIdAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(SINGLE_PRESCRIPTION_LIST_GET_BY_ID_ENDPOINT, {
      id: id,
    }),
    payload
  );
};

//Get Prescription How Much List
export const getPrescriptionHowMuchListAPI = (payload) => {
  return API.post(GET_PRESCRIPTION_HOW_MUCH_LIST_ENDPOINT, payload);
};

//Get Patient Name List
export const getPatientNameListAPI = (payload) => {
  return API.post(GET_PATIENT_NAME_LIST_ENDPOINT, payload);
};

//Get Drug List
export const getDrugListAPI = (payload) => {
  return API.post(GET_DRUG_LIST_ENDPOINT, payload);
};

//Get Sign Link
export const getSignLinkAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_SIGN_LINK_ENDPOINT, {
      id: id,
    }),
    payload
  );
};

//After Success Sign Download PDF
export const getDownloadPdfAPI = (payload) => {
  return API.post(GET_DOWNLOAD_ZOHO_SIGN_PDF_ENDPOINT, payload);
};

//Get Signature
export const getSignatureAPI = (payload) => {
  return API.post(GET_SIGNATURE_ENDPOINT, payload);
};

//Create Signature
export const getCreateSignatureAPI = (payload) => {
  return API.post(CREATE_SIGNATURE_ENDPOINT, payload);
};
