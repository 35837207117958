import {
  GET_PATIENT_VIEW_DETAILS,
  SUCCESS_PATIENT_VIEW_DETAILS,
  ERROR_PATIENT_VIEW_DETAILS,
  START_CREATE_DELIVER_ADDRESS,
  SUCCESS_CREATE_DELIVER_ADDRESS,
  ERROR_CREATE_DELIVER_ADDRESS,
  START_UPDATE_DELIVER_ADDRESS,
  SUCCESS_UPDATE_DELIVER_ADDRESS,
  ERROR_UPDATE_DELIVER_ADDRESS,
  GET_SINGLE_DELIVER_ADDRESS_BY_ID,
  SUCCESS_SINGLE_DELIVER_ADDRESS_BY_ID,
  ERROR_SINGLE_DELIVER_ADDRESS_BY_ID,
  START_DELETE_DELIVER_ADDRESS,
  SUCCESS_DELETE_DELIVER_ADDRESS,
  ERROR_DELETE_DELIVER_ADDRESS,
  RESET_DELIVER_ADDRESS,
  GET_PATIENT_ADDRESS_DETAILS,
  SUCCESS_PATIENT_ADDRESS_DETAILS,
  ERROR_PATIENT_ADDRESS_DETAILS,
  START_PAYMENT_METHOD,
  SUCCESS_PAYMENT_METHOD,
  ERROR_PAYMENT_METHOD,
  GET_PICKUP_CODE,
  SUCCESS_PICKUP_CODE,
  ERROR_PICKUP_CODE,
  SET_PICKUP_OPTION_CODE,
  SUCCESS_PICKUP_OPTION_CODE,
  ERROR_PICKUP_OPTION_CODE,
} from "../Action/PatientViewAction";

const initialState = {
  getPatientViewDetailsList: {
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  createDeliveryAddressData: {
    loading: false,
    data: [],
    error: {},
  },
  deleteDeliveryAddressData: {
    loading: false,
    data: {},
    error: {},
  },
  updateDeliveryAddressData: {
    loading: false,
    data: {},
    error: {},
  },
  singleDeliveryAddressData: {
    loading: false,
    data: {},
    error: {},
  },
  getPrescriptionAddressList: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  getPaymentSecretKeyDetails: {
    loading: false,
    data: {},
    error: {},
  },
  paymentMethodData: {
    loading: false,
    data: {},
    error: {},
  },
  getPickupCode: {
    loading: false,
    data: {},
    error: {},
  },
  setPickupOption: {
    loading: false,
    data: {},
    error: {},
  },
};

const PatientViewReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DELIVER_ADDRESS: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action.key],
      };
    }
    case GET_PATIENT_VIEW_DETAILS: {
      return {
        ...state,
        getPatientViewDetailsList: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_PATIENT_VIEW_DETAILS: {
      return {
        ...state,
        getPatientViewDetailsList: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_PATIENT_VIEW_DETAILS: {
      return {
        ...state,
        getPatientViewDetailsList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_DELIVER_ADDRESS: {
      return {
        ...state,
        createDeliveryAddressData: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_CREATE_DELIVER_ADDRESS: {
      return {
        ...state,
        createDeliveryAddressData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_CREATE_DELIVER_ADDRESS: {
      return {
        ...state,
        createDeliveryAddressData: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case START_DELETE_DELIVER_ADDRESS: {
      return {
        ...state,
        deleteDeliveryAddressData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_DELETE_DELIVER_ADDRESS: {
      return {
        ...state,
        deleteDeliveryAddressData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_DELETE_DELIVER_ADDRESS: {
      return {
        ...state,
        deleteDeliveryAddressData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_DELIVER_ADDRESS: {
      return {
        ...state,
        updateDeliveryAddressData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_UPDATE_DELIVER_ADDRESS: {
      return {
        ...state,
        updateDeliveryAddressData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_DELIVER_ADDRESS: {
      return {
        ...state,
        updateDeliveryAddressData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SINGLE_DELIVER_ADDRESS_BY_ID: {
      let oldList = state?.singleDeliveryAddressData?.data;
      return {
        ...state,
        singleDeliveryAddressData: {
          loading: true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_SINGLE_DELIVER_ADDRESS_BY_ID: {
      return {
        ...state,
        singleDeliveryAddressData: {
          loading: false,
          data: action?.payload?.data,
          error: {},
        },
      };
    }
    case ERROR_SINGLE_DELIVER_ADDRESS_BY_ID: {
      return {
        ...state,
        singleDeliveryAddressData: {
          loading: false,
          data: {},
          error: {},
        },
      };
    }
    case GET_PATIENT_ADDRESS_DETAILS: {
      return {
        ...state,
        getPrescriptionAddressList: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_PATIENT_ADDRESS_DETAILS: {
      return {
        ...state,
        getPrescriptionAddressList: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_PATIENT_ADDRESS_DETAILS: {
      return {
        ...state,
        getPrescriptionAddressList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethodData: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethodData: {
          loading: false,
          data: action?.payload?.data,
          error: {},
        },
      };
    }
    case ERROR_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethodData: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_PICKUP_CODE: {
      return {
        ...state,
        getPickupCode: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_PICKUP_CODE: {
      return {
        ...state,
        getPickupCode: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_PICKUP_CODE: {
      return {
        ...state,
        getPickupCode: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case SET_PICKUP_OPTION_CODE: {
      return {
        ...state,
        setPickupOption: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_PICKUP_OPTION_CODE: {
      return {
        ...state,
        setPickupOption: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_PICKUP_OPTION_CODE: {
      return {
        ...state,
        setPickupOption: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default PatientViewReducers;
