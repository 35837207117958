import React from "react";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { TitleList } from "utils/data";
import PrimaryIcon from "../../Components/Common/PrimaryIcon";
import { ReactComponent as userIcon } from "../../Assets/userIcon.svg";
import { ReactComponent as LastName } from "../../Assets/LastName.svg";
import { ReactComponent as MailIcon } from "../../Assets/MailIcon.svg";
import { ReactComponent as PhoneIcon } from "../../Assets/PhoneIcon.svg";
import { ReactComponent as salutationIcon } from "../../Assets/salutationIcon.svg";

export default function ClinicLeadDetails({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) {
  return (
    <>
      <FormControl
        error={touched?.title && errors?.title}
        fullWidth
        size="small"
      >
        <Select
          name="title"
          value={<>&nbsp;&nbsp;&nbsp;&nbsp;{values?.title}</>}
          onChange={(e) => {
            setFieldValue("title", e.target.value);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          isPlaceholder={!values?.title}
          renderValue={(value) => {
            return (
              <Box sx={{ display: "flex", gap: 1 }}>
                <PrimaryIcon width="20px" height="20px" icon={salutationIcon} />
                {!values?.title && <>&nbsp;&nbsp;&nbsp;&nbsp; Select Title *</>}
                {value}
              </Box>
            );
          }}
        >
          {TitleList?.map((list, index) => {
            return (
              <MenuItem value={list.label} key={index}>
                {list.label}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText error={touched?.title && errors?.title}>
          {touched?.title && errors?.title}
        </FormHelperText>
      </FormControl>
      <TextField
        margin="normal"
        fullWidth
        value={values.first_name}
        name="first_name"
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);
        }}
        placeholder="First Name *"
        type="first_name"
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start">
                <PrimaryIcon width="24px" icon={userIcon} />
              </InputAdornment>
            </>
          ),
        }}
        error={touched.first_name && errors.first_name}
        helperText={touched.first_name && errors.first_name}
      />
      <TextField
        margin="normal"
        fullWidth
        value={values.last_name}
        name="last_name"
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);
        }}
        placeholder="Last Name *"
        type="last_name"
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start">
                <PrimaryIcon width="24px" icon={LastName} />
              </InputAdornment>
            </>
          ),
        }}
        error={touched.last_name && errors.last_name}
        helperText={touched.last_name && errors.last_name}
      />
      <TextField
        margin="normal"
        fullWidth
        value={values.personal_mobile_number}
        name="personal_mobile_number"
        placeholder="Personal Mobile Number *"
        type="personal_mobile_number"
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);
        }}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start">
                <PrimaryIcon width="24px" icon={PhoneIcon} />
              </InputAdornment>
            </>
          ),
        }}
        error={touched.personal_mobile_number && errors.personal_mobile_number}
        helperText={
          touched.personal_mobile_number && errors.personal_mobile_number
        }
      />
      <TextField
        margin="normal"
        fullWidth
        value={values.personal_email}
        name="personal_email"
        placeholder="Personal Email Address*"
        type="personal_email"
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);
        }}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start">
                <PrimaryIcon width="24px" icon={MailIcon} />
              </InputAdornment>
            </>
          ),
        }}
        error={touched.personal_email && errors.personal_email}
        helperText={touched.personal_email && errors.personal_email}
      />
    </>
  );
}
