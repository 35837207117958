import {
  GET_STAFF_MEMBER_LIST,
  SUCCESS_STAFF_MEMBER_LIST,
  ERROR_STAFF_MEMBER_LIST,
  START_CREATE_STAFF_MEMBER,
  SUCCESS_CREATE_STAFF_MEMBER,
  ERROR_CREATE_STAFF_MEMBER,
  START_UPDATE_STAFF_MEMBER,
  SUCCESS_UPDATE_STAFF_MEMBER,
  ERROR_UPDATE_STAFF_MEMBER,
  GET_SINGLE_STAFF_MEMBER_LIST_BY_ID,
  SUCCESS_SINGLE_STAFF_MEMBER_LIST_BY_ID,
  ERROR_SINGLE_STAFF_MEMBER_LIST_BY_ID,
  START_DELETE_STAFF_MEMBER,
  SUCCESS_DELETE_STAFF_MEMBER,
  ERROR_DELETE_STAFF_MEMBER,
  RESET_STAFF_MEMBER,
  START_REPORTING_PERSON_LIST,
  SUCCESS_REPORTING_PERSON_LIST,
  ERROR_REPORTING_PERSON_LIST,
} from "../Action/StaffMemberAction";

const initialState = {
  getStaffMemberList: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  createStaffMemberData: {
    loading: false,
    data: [],
    error: {},
  },
  updateStaffMemberData: {
    loading: false,
    data: {},
    error: {},
  },
  singleStaffMemberData: {
    loading: false,
    data: {},
    error: {},
  },
  deleteStaffMemberData: {
    loading: false,
    data: {},
    error: {},
  },
  reportingPersonList: {
    loading: false,
    data: {},
    error: {},
  },
};

const StaffMemberReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STAFF_MEMBER: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action.key],
      };
    }
    case GET_STAFF_MEMBER_LIST: {
      let oldList = state?.getStaffMemberList?.data;
      return {
        ...state,
        getStaffMemberList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_STAFF_MEMBER_LIST: {
      let oldList = state?.getStaffMemberList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getStaffMemberList: {
          loading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_STAFF_MEMBER_LIST: {
      return {
        ...state,
        getStaffMemberList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_STAFF_MEMBER: {
      return {
        ...state,
        createStaffMemberData: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_CREATE_STAFF_MEMBER: {
      let oldList = state?.getStaffMemberList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [action?.payload?.data, ...oldList?.list];
        oldList.filtered_record = oldList?.filtered_record + 1;
      }
      return {
        ...state,
        createStaffMemberData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_CREATE_STAFF_MEMBER: {
      return {
        ...state,
        createStaffMemberData: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case START_UPDATE_STAFF_MEMBER: {
      return {
        ...state,
        updateStaffMemberData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_UPDATE_STAFF_MEMBER: {
      let oldList = state?.getStaffMemberList?.data;
      oldList.list = oldList?.list?.map((item) => {
        if (item?.id === action?.id) {
          return {
            ...item,
            ...action?.payload?.data,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        updateStaffMemberData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_STAFF_MEMBER: {
      return {
        ...state,
        updateStaffMemberData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SINGLE_STAFF_MEMBER_LIST_BY_ID: {
      let oldList = state?.singleStaffMemberData?.data;
      return {
        ...state,
        singleStaffMemberData: {
          loading: true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_SINGLE_STAFF_MEMBER_LIST_BY_ID: {
      let oldList = state?.singleStaffMemberData?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        singleStaffMemberData: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_SINGLE_STAFF_MEMBER_LIST_BY_ID: {
      return {
        ...state,
        singleStaffMemberData: {
          loading: false,
          data: {},
          error: {},
        },
      };
    }
    case START_DELETE_STAFF_MEMBER: {
      return {
        ...state,
        deleteStaffMemberData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_DELETE_STAFF_MEMBER: {
      let oldList = state?.getStaffMemberList?.data;
      oldList.list = oldList?.list?.filter((item) => item?.id !== action?.id);
      oldList.filtered_record = oldList?.filtered_record - 1;
      return {
        ...state,
        deleteStaffMemberData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_DELETE_STAFF_MEMBER: {
      return {
        ...state,
        deleteStaffMemberData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_REPORTING_PERSON_LIST: {
      let oldList = state?.reportingPersonList?.data;
      return {
        ...state,
        reportingPersonList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_REPORTING_PERSON_LIST: {
      let oldList = state?.reportingPersonList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        reportingPersonList: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_REPORTING_PERSON_LIST: {
      return {
        ...state,
        reportingPersonList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default StaffMemberReducers;
