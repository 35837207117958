import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  START_DASHBOARD_LIST,
  SUCCESS_DASHBOARD_LIST,
  ERROR_DASHBOARD_LIST,
} from "../Action/DashBoardAction";
import { getDashboardListAPI } from "../Api/DashboardApis";

function* getDashboardListWorker() {
  try {
    const res = yield call(getDashboardListAPI);
    if (res?.success) {
      yield put({
        type: SUCCESS_DASHBOARD_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_DASHBOARD_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* DashboardWatcher() {
  yield takeEvery(START_DASHBOARD_LIST, getDashboardListWorker);
}
