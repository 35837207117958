export const MuiTypography = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      lineHeight: 1.5,
      color: theme.palette?.[ownerState?.color || "secondary"]?.main,
    }),
  },
  variants: [
    {
      props: { variant: "h1" },
      style: ({ theme, ownerState }) => ({
        fontSize: "50px",
        fontStyle: "normal",
        color: theme.palette?.[ownerState?.color || "secondary"]?.main,
      }),
    },
    {
      props: { variant: "h2" },
      style: ({ theme, ownerState }) => ({
        fontSize: "32px ",
        fontStyle: "normal",
        color: theme.palette?.[ownerState?.color || "secondary"]?.main,
      }),
    },
    {
      props: { variant: "h3" },
      style: ({ theme, ownerState }) => ({
        fontSize: "24px ",
        fontStyle: "normal",
        color: theme.palette?.[ownerState?.color || "secondary"]?.main,
      }),
    },
    {
      props: { variant: "h4" },
      style: ({ theme, ownerState }) => ({
        fontSize: "14px !important",
        fontStyle: "normal",
        color: theme.palette?.[ownerState?.color || "secondary"]?.main,
      }),
    },
    {
      props: { variant: "h5" },
      style: ({ theme, ownerState }) => ({
        fontSize: "14px",
        fontStyle: "normal",
        color: theme.palette?.[ownerState?.color || "secondary"]?.main,
      }),
    },
    {
      props: { variant: "h6" },
      style: ({ theme, ownerState }) => ({
        fontSize: "12px !important",
        fontStyle: "normal",
        color: theme.palette?.[ownerState?.color || "secondary"]?.main,
      }),
    },
    {
      props: { upperCase: true },
      style: {
        textTransform: "uppercase",
      },
    },
    {
      props: { bold: true },
      style: {
        fontWeight: "700",
      },
    },
    {
      props: { semiBold: true },
      style: {
        fontWeight: "600",
      },
    },
    {
      props: { medium: true },
      style: {
        fontWeight: "500",
      },
    },
    {
      props: { regular: true },
      style: {
        fontWeight: "400",
      },
    },
  ],
};

export const MuiButton = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      textTransform: "none ",
      "&:disabled": {},
    }),
  },
  variants: [
    {
      props: { variant: "contained" },
      style: ({ theme, ownerState }) => ({
        borderRadius: "12px",
        padding: "8px 20px",
        height: "40px",
        color: theme.palette.white.main,
        fontSize: "16px",
        fontWeight: "700",

        fontStyle: "normal",
        " &:disabled": {
          color: `${theme.palette.secondary.main}90 !important`,
          "&>span": {
            "&>svg": {
              fill: `${theme.palette.secondary.main}90 !important`,
            },
          },
        },
      }),
    },
    {
      props: { variant: "outlined" },
      style: ({ theme, ownerState }) => ({
        borderRadius: "5px",
        padding: "8px 20px !important",
        height: "40px",
        color: theme.palette.primary.main,
        fontSize: "16px",
        background: `${theme.palette.primary.main}10`,
        "&:hover": {
          background: `${theme.palette.primary.main}20`,
        },
        " &:disabled": {
          background: `${theme.palette.secondary.main}10 !important`,
          "&>span": {
            "&>svg": {
              fill: `${theme.palette.secondary.main}80 !important`,
            },
          },
        },
      }),
    },
    {
      props: { medium: true },
      style: {
        height: "30px",
        padding: "5px 18x !important",
      },
    },
    {
      props: { upperCase: true },
      style: {
        textTransform: "uppercase",
      },
    },
  ],
};

export const MuiTextField = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      "&>label": {
        fontSize: "8px !important",
      },
      "&>div": {
        fontStyle: "normal",
        borderRadius: "12px",
        fontSize: "16px",
        background: theme.palette.white.main,
        color: theme.palette.background.blackText,
        height: "56px",
        padding: "8px 16px !important",
        "&:hover": {
          borderColor: "none !important",
        },
        "&>input": {
          "&::placeholder": {
            color: theme.palette.background.tableTextColor,
            opacity: 1,
          },
        },
        "&>fieldset": {
          borderColor: theme.palette.background.field,
        },
      },
      "& .Mui-focused": {
        "&>fieldset": {
          borderWidth: "1px !important",
        },
      },
      "& .MuiInputLabel-root": {
        transform: "translate(14px, 11px) scale(1.5) !important",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(12px, -9px) scale(1.5) !important",
        "&.MuiInputLabel-sizeSmall": {
          transform: "translate(12px, -9px) scale(1.5) !important",
        },
      },
    }),
  },
  variants: [
    {
      props: { secondary: true },
      style: ({ theme, ownerState }) => ({
        height: ownerState?.medium ? "40px" : "50px",
        "&>div": {
          height: ownerState?.medium ? "40px" : "50px",
          borderRadius: "4px",
          background: theme.palette.white.main,
          // boxShadow: "0px 0px 10px rgba(90, 104, 114, 0.1)",
          "&>input": {
            height: "50px !important",
            padding: ownerState?.withPadding ? "0px 14px" : "0px",
            color: theme.palette.background.field,
            fontSize: "16px",
            fontWeight: 400,
          },
          // "&>fieldset": {
          //   border: "none",
          // },
        },
      }),
    },
  ],
};

export const MuiFormControl = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      "&>div": {
        height: "56px",
        width: "100% !important",
        borderRadius: "12px",
        background: theme.palette.white.main,
        color: theme.palette.background.blackText,
        "&>input": {
          padding: "0px 14px",
          fontSize: "16px",
          fontWeight: 400,

          fontStyle: "normal",
        },
        "&>fieldset": {
          borderColor: theme.palette.background.field,
        },
      },
      "& .Mui-focused": {
        "&>fieldset": {
          borderWidth: "1px !important",
        },
      },
      "& .MuiFormLabel-root": {
        transform: "translate(14px, 8px) scale(1)",
      },
    }),
  },
  variants: [
    {
      props: { secondary: true },
      style: ({ theme, ownerState }) => ({
        height: ownerState?.medium ? "40px" : "50px",
        "&>div": {
          height: ownerState?.medium ? "40px" : "50px",
          borderRadius: "4px",
          background: theme.palette.white.main,
          "&>input": {
            height: "50px !important",
            padding: ownerState?.withPadding ? "0px 14px" : "0px",
            color: theme.palette.secondary.main,
            fontSize: "16px",
          },
        },
      }),
    },
  ],
};

export const MuiAutocomplete = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      "&>label": {
        fontSize: "10px !important",
      },
      "&>div": {
        // height: "56px",
        borderRadius: "5px",
        background: theme.palette.white.main,
        color: theme.palette.secondary.main,
        "&>input": {
          fontSize: "14px",
        },
      },
      "& .MuiOutlinedInput-root": {
        padding: "4px !important",
        paddingRight: "50px !important",
        "&>input": {
          minWidth: "0px !important",
          // height: "56px",
        },
      },
      "& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input":
        {
          minWidth: "0px !important",
        },
      "& .Mui-focused": {
        "&>fieldset": {
          borderWidth: "1px !important",
        },
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(12px, -9px) scale(0.90)",
      },
    }),
  },
  variants: [
    {
      props: { secondary: true },
      style: ({ theme, ownerState }) => ({
        height: ownerState?.medium ? "40px" : "50px",
        "&>div": {
          height: ownerState?.medium ? "40px" : "50px",
          borderRadius: "4px",
          background: theme.palette.white.main,
          boxShadow: "0px 0px 10px rgba(90, 104, 114, 0.1)",
          "&>input": {
            height: "50px !important",
            padding: ownerState?.withPadding ? "0px 14px" : "0px",
            color: theme.palette.secondary.main,
            fontSize: "16px",
          },
        },
      }),
    },
  ],
};

export const MuiAvatar = {
  MuiAvatar: {
    variants: [
      {
        props: { variant: "rounded" },
        style: {
          borderRadius: "50%",
          img: {
            borderRadius: "50%",
          },
        },
      },
      {
        props: { isActive: true },
        style: ({ ownerState, theme }) => ({
          "&:after": {
            position: "absolute",
            content: !ownerState?.noBorder && "''",
            width: ownerState?.dotLength || "12px",
            height: ownerState?.dotLength || "12px",
            right: ownerState?.variant === "rounded" ? "3px" : "-3px",
            bottom: ownerState?.variant === "rounded" ? "3px" : "-3px",
            borderRadius: "50%",
            zIndex: 2,
            border: `2px solid ${theme.palette.background.paper}`,
            backgroundColor: `${theme.palette.green.main}`,
          },
        }),
      },
    ],
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderRadius: "5px",
        width: "40px",
        height: "40px",
        padding: !ownerState?.noBorder ? "2px" : "0px",
        overflow: "inherit",
        "&:before": {
          position: "absolute",
          content: !ownerState?.noBorder && "''",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          borderRadius: ownerState?.variant === "rounded" ? "50px" : "5px",
          backgroundColor: `${theme.palette.background.paper}`,
        },
        "&>span": {
          position: "relative",
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontSize: "20px",
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          background: theme.palette.background.default,
          borderRadius: ownerState?.variant === "rounded" ? "50%" : "2px",
        },
      }),
      img: {
        borderRadius: "5px",
        position: "relative",
      },
    },
  },
};

export const MuiLink = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      cursor: "pointer",
    }),
  },
};

export const MuiChip = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderRadius: "30px",
      height: "27px",
    }),
  },
};

export const MuiDivider = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      background: theme.palette?.[ownerState?.color || "secondary"]?.main,
    }),
  },
};

export const MuiTable = {
  MuiTableContainer: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        height: ownerState?.maxHeight || "calc(100vh - 200px)",
        width: "100%",
        borderRadius: "10px",
        padding: "15px 10px",
        boxShadow: "0px 0px 10px rgba(26, 102, 122, 0.1)",
        background: theme.palette.background.paper,
      }),
    },
  },

  MuiTableHead: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        boxShadow: "none",
        "&>tr": {
          "&>th": {
            backgroundColor: theme.palette.background.default,
            border: `1px solid ${theme.palette.background.tableBorder}`,
            padding: "16px 20px",
            borderBottom: "0px",
            textAlign: "center",
            "&:first-child": {
              maxwidth: "80px !important",
            },
            "&:last-child": {
              width: "40px",
            },
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            color: theme.palette.background.tableTextColor,
          },
        },
      }),
    },
  },

  MuiTableBody: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        boxShadow: "none",
        "&>tr": {
          "&>td": {
            padding: "5px 20px",
            borderBottom: `1px solid ${theme.palette.background.grey}`,
            textAlign: "center",
            border: `1px solid ${theme.palette.background.tableBorder}`,
            color: theme.palette.secondary.main,
            "&:first-child": {
              fontWeight: "500",
              color: `${theme.palette.background.blackText} !important`,
            },
          },
        },
      }),
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        fontWeight: ownerState?.semiBold ? "600" : "",
      }),
    },
  },
};

export const MuiDialog = {
  MuiDialog: {
    styleOverrides: {
      paper: ({ ownerState, theme }) => ({
        position: "absolute",
        top: "100px",
        width: "100%",
        padding: "0px 20px",
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        padding: "18px 0px 10px 0px",
        borderBottom: `1px solid ${theme.palette.background.grey}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&>p": {
          fontSize: "24px",
          fontWeight: "500",
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        padding: "20px 0px",
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        padding: "10px 0px 20px 0px",
        justifyContent: "center",
        borderTop: `1px solid ${theme.palette.background.grey}`,
        "& :not(:first-of-type)": {
          marginLeft: "20px",
        },
      }),
    },
  },
};

export const MuiSelect = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      "&>div": {
        color: ownerState?.isPlaceholder
          ? theme.palette.background.tableTextColor
          : "inherit",
      },
    }),
  },
};
