import {
  START_SET_LOGIN_PASSWORD,
  SUCCESS_SET_LOGIN_PASSWORD,
  ERROR_SET_LOGIN_PASSWORD,
  START_LOGIN,
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  RESET_STATE,
  START_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  START_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  ERROR_RESET_PASSWORD,
  START_SET_LOGIN_VERIFY_LINK,
  SUCCESS_SET_LOGIN_VERIFY_LINK,
  ERROR_SET_LOGIN_VERIFY_LINK,
  START_SIGN_UP,
  SUCCESS_SIGN_UP,
  ERROR_SIGN_UP,
} from "../Action/AuthAction";

const initialState = {
  setLoginPasswordDetails: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  loginDetails: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  logoutData: {
    success: null,
    isLoading: false,
    data: [],
    message: "",
  },
  forgotPasswordDetail: {
    isLoading: false,
    data: {},
    errors: {},
    success: null,
    message: "",
  },
  resetPasswordDetail: {
    isLoading: false,
    data: {},
    errors: {},
    success: null,
    message: "",
  },
  setLoginVerifyLink: {
    isLoading: false,
    data: {},
    errors: {},
    success: null,
    message: "",
  },
  signUpDetails: {
    isLoading: false,
    data: {},
    error: {},
    success: null,
    message: "",
  },
};

const AuthReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case START_SET_LOGIN_PASSWORD: {
      return {
        ...previousState,
        setLoginPasswordDetails: {
          ...previousState.setLoginPasswordDetails,
          isLoading: true,
        },
      };
    }
    case SUCCESS_SET_LOGIN_PASSWORD: {
      return {
        ...previousState,
        setLoginPasswordDetails: {
          ...previousState.setLoginPasswordDetails,
          success: true,
          isLoading: false,
          data: action,
          message: action.data.message,
        },
      };
    }
    case ERROR_SET_LOGIN_PASSWORD: {
      return {
        ...previousState,
        setLoginPasswordDetails: {
          ...previousState.setLoginPasswordDetails,
          success: false,
          isLoading: false,
          message: action.data.message,
          errors: action.data.errors,
        },
      };
    }
    case START_LOGIN: {
      return {
        ...previousState,
        loginDetails: {
          ...previousState.loginDetails,
          isLoading: true,
        },
      };
    }
    case SUCCESS_LOGIN: {
      return {
        ...previousState,
        loginDetails: {
          ...previousState.loginDetails,
          success: true,
          isLoading: false,
          data: action,
          message: action.data.message,
        },
      };
    }
    case ERROR_LOGIN: {
      return {
        ...previousState,
        loginDetails: {
          ...previousState.loginDetails,
          success: false,
          isLoading: false,
          message: action.data.message,
          errors: action.data.errors,
        },
      };
    }
    case LOGOUT_START: {
      return {
        ...previousState,
        logoutData: {
          ...previousState.logoutData,
          isLoading: true,
        },
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...previousState,
        logoutData: {
          ...previousState.logoutData,
          isLoading: false,
          success: true,
          message: action.payload.message,
        },
      };
    }
    case LOGOUT_ERROR: {
      return {
        ...previousState,
        logoutData: {
          ...previousState.logoutData,
          success: false,
          isLoading: false,
          message: action.payload.message,
          errors: action.payload.errors,
        },
      };
    }
    case START_FORGOT_PASSWORD: {
      return {
        ...previousState,
        forgotPasswordDetail: {
          ...previousState.forgotPasswordDetail,
          isLoading: true,
        },
      };
    }
    case SUCCESS_FORGOT_PASSWORD: {
      return {
        ...previousState,
        forgotPasswordDetail: {
          ...previousState.forgotPasswordDetail,
          isLoading: false,
          success: true,
          message: action.payload.message,
        },
      };
    }
    case ERROR_FORGOT_PASSWORD: {
      return {
        ...previousState,
        forgotPasswordDetail: {
          ...previousState.forgotPasswordDetail,
          success: false,
          isLoading: false,
          message: action.payload.message,
          errors: action.payload.errors,
        },
      };
    }
    case START_RESET_PASSWORD: {
      return {
        ...previousState,
        resetPasswordDetail: {
          ...previousState.resetPasswordDetail,
          isLoading: true,
        },
      };
    }
    case SUCCESS_RESET_PASSWORD: {
      return {
        ...previousState,
        resetPasswordDetail: {
          ...previousState.resetPasswordDetail,
          isLoading: false,
          success: true,
          data: action,
          message: action.data.message,
        },
      };
    }
    case ERROR_RESET_PASSWORD: {
      return {
        ...previousState,
        resetPasswordDetail: {
          ...previousState.resetPasswordDetail,
          success: false,
          isLoading: false,
          message: action.payload.message,
          errors: action.payload.errors,
        },
      };
    }
    case RESET_STATE: {
      return {
        ...previousState,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_SET_LOGIN_VERIFY_LINK: {
      return {
        ...previousState,
        setLoginVerifyLink: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SET_LOGIN_VERIFY_LINK: {
      return {
        ...previousState,
        setLoginVerifyLink: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SET_LOGIN_VERIFY_LINK: {
      return {
        ...previousState,
        setLoginVerifyLink: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_SIGN_UP: {
      return {
        ...previousState,
        signUpDetails: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SIGN_UP: {
      return {
        ...previousState,
        signUpDetails: {
          isLoading: false,
          data: action,
          error: "",
        },
      };
    }
    case ERROR_SIGN_UP: {
      return {
        ...previousState,
        signUpDetails: {
          isLoading: false,
          error: action,
          data: {},
        },
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default AuthReducers;
