import {
  GET_DOCTORS_LIST,
  SUCCESS_DOCTORS_LIST,
  ERROR_DOCTORS_LIST,
  START_CREATE_DOCTORS,
  SUCCESS_CREATE_DOCTORS,
  ERROR_CREATE_DOCTORS,
  GET_SINGLE_DOCTORS_LIST_BY_ID,
  SUCCESS_SINGLE_DOCTORS_LIST_BY_ID,
  ERROR_SINGLE_DOCTORS_LIST_BY_ID,
  START_UPDATE_DOCTORS,
  SUCCESS_UPDATE_DOCTORS,
  ERROR_UPDATE_DOCTORS,
  START_DELETE_DOCTOR,
  SUCCESS_DELETE_DOCTOR,
  ERROR_DELETE_DOCTOR,
  RESET_DOCTOR_STATE,
  START_ASSIGN_OTHER_DOCTOR,
  SUCCESS_ASSIGN_OTHER_DOCTOR,
  ERROR_ASSIGN_OTHER_DOCTOR,
  START_STAFF_AND_DOCTOR_COUNT,
  SUCCESS_STAFF_AND_DOCTOR_COUNT,
  ERROR_STAFF_AND_DOCTOR_COUNT,
} from "../Action/DoctorsAction";

const initialState = {
  getDoctorsList: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  createDoctorData: {
    loading: false,
    data: [],
    error: {},
  },
  updateDoctorData: {
    loading: false,
    data: {},
    error: {},
  },
  singleDoctorData: {
    loading: false,
    data: {},
    error: {},
  },
  deleteDoctorData: {
    loading: false,
    data: {},
    error: {},
  },
  assignToOtherDoctor: {
    loading: false,
    data: [],
    error: {},
  },
  getStaffAndDoctorCount: {
    loading: false,
    data: {},
    error: {},
  },
};

const DoctorReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DOCTOR_STATE: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action.key],
      };
    }
    case GET_DOCTORS_LIST: {
      let oldList = state?.getDoctorsList?.data;
      return {
        ...state,
        getDoctorsList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_DOCTORS_LIST: {
      let oldList = state?.getDoctorsList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getDoctorsList: {
          loading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_DOCTORS_LIST: {
      return {
        ...state,
        getDoctorsList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_DOCTORS: {
      return {
        ...state,
        createDoctorData: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_CREATE_DOCTORS: {
      let oldList = state?.getDoctorsList?.data;
      oldList.list = [action?.payload?.data, ...oldList?.list];
      oldList.filtered_record = oldList?.filtered_record + 1;
      return {
        ...state,
        createDoctorData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_CREATE_DOCTORS: {
      return {
        ...state,
        createDoctorData: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case START_UPDATE_DOCTORS: {
      return {
        ...state,
        updateDoctorData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_UPDATE_DOCTORS: {
      let oldList = state?.getDoctorsList?.data;
      oldList.list = oldList?.list?.map((item) => {
        if (item?.id === action?.id) {
          return {
            ...item,
            ...action?.payload?.data,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        updateDoctorData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_DOCTORS: {
      return {
        ...state,
        updateDoctorData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SINGLE_DOCTORS_LIST_BY_ID: {
      let oldList = state?.singleDoctorData?.data;
      return {
        ...state,
        singleDoctorData: {
          loading: true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_SINGLE_DOCTORS_LIST_BY_ID: {
      let oldList = state?.singleDoctorData?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        singleDoctorData: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_SINGLE_DOCTORS_LIST_BY_ID: {
      return {
        ...state,
        singleDoctorData: {
          loading: false,
          data: {},
          error: {},
        },
      };
    }
    case START_DELETE_DOCTOR: {
      return {
        ...state,
        deleteDoctorData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_DELETE_DOCTOR: {
      let oldList = state?.getDoctorsList?.data;
      oldList.list = oldList?.list?.filter((item) => item?.id !== action?.id);
      oldList.filtered_record = oldList?.filtered_record - 1;
      return {
        ...state,
        deleteDoctorData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_DELETE_DOCTOR: {
      return {
        ...state,
        deleteDoctorData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_ASSIGN_OTHER_DOCTOR: {
      return {
        ...state,
        assignToOtherDoctor: {
          loading: true,
          data: [],
          error: {},
        },
      };
    }
    case SUCCESS_ASSIGN_OTHER_DOCTOR: {
      return {
        ...state,
        assignToOtherDoctor: {
          loading: false,
          data: action?.payload,
          error: {},
        },
      };
    }
    case ERROR_ASSIGN_OTHER_DOCTOR: {
      return {
        ...state,
        assignToOtherDoctor: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case START_STAFF_AND_DOCTOR_COUNT: {
      return {
        ...state,
        getStaffAndDoctorCount: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_STAFF_AND_DOCTOR_COUNT: {
      return {
        ...state,
        getStaffAndDoctorCount: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_STAFF_AND_DOCTOR_COUNT: {
      return {
        ...state,
        getStaffAndDoctorCount: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default DoctorReducers;
