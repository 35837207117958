import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_PATIENT_VIEW_DETAILS,
  SUCCESS_PATIENT_VIEW_DETAILS,
  ERROR_PATIENT_VIEW_DETAILS,
  START_CREATE_DELIVER_ADDRESS,
  SUCCESS_CREATE_DELIVER_ADDRESS,
  ERROR_CREATE_DELIVER_ADDRESS,
  START_UPDATE_DELIVER_ADDRESS,
  SUCCESS_UPDATE_DELIVER_ADDRESS,
  ERROR_UPDATE_DELIVER_ADDRESS,
  GET_SINGLE_DELIVER_ADDRESS_BY_ID,
  SUCCESS_SINGLE_DELIVER_ADDRESS_BY_ID,
  ERROR_SINGLE_DELIVER_ADDRESS_BY_ID,
  START_DELETE_DELIVER_ADDRESS,
  SUCCESS_DELETE_DELIVER_ADDRESS,
  ERROR_DELETE_DELIVER_ADDRESS,
  GET_PATIENT_ADDRESS_DETAILS,
  SUCCESS_PATIENT_ADDRESS_DETAILS,
  ERROR_PATIENT_ADDRESS_DETAILS,
  START_PAYMENT_METHOD,
  SUCCESS_PAYMENT_METHOD,
  ERROR_PAYMENT_METHOD,
  GET_PICKUP_CODE,
  SUCCESS_PICKUP_CODE,
  ERROR_PICKUP_CODE,
  SET_PICKUP_OPTION_CODE,
  SUCCESS_PICKUP_OPTION_CODE,
  ERROR_PICKUP_OPTION_CODE,
} from "../Action/PatientViewAction";
import {
  getPatientViewAPI,
  getPatientAddressAPI,
  updateDeliveryAddressAPI,
  deleteDeliveryAddressAPI,
  getSingleDeliveryAddressByIdAPI,
  createDeliveryAddressWithPaymentAPI,
  PaymentMethodAPI,
  getPickupCodeAPI,
  setPickupOptionAPI,
} from "../Api/PatientViewApis";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";

function* getPatientWorker({ id }) {
  try {
    const res = yield call(getPatientViewAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_PATIENT_VIEW_DETAILS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PATIENT_VIEW_DETAILS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* createDeliveryAddressWorker({ id, payload }) {
  try {
    const res = yield call(createDeliveryAddressWithPaymentAPI, id, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_DELIVER_ADDRESS,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Patient delivery address saved successfully!",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_DELIVER_ADDRESS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.data.errors[0] || res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteDeliveryAddressWorker({ id }) {
  try {
    const res = yield call(deleteDeliveryAddressAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_DELETE_DELIVER_ADDRESS,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Patient delivery address deleted successfully!",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_DELIVER_ADDRESS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.errors.id[0],
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateDeliveryAddressWorker({ id, payload }) {
  try {
    const res = yield call(updateDeliveryAddressAPI, id, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_UPDATE_DELIVER_ADDRESS,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Patient delivery address updated successfully!",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_DELIVER_ADDRESS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSingleDeliveryAddressGetByIdWorker({ id }) {
  try {
    const res = yield call(getSingleDeliveryAddressByIdAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_SINGLE_DELIVER_ADDRESS_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SINGLE_DELIVER_ADDRESS_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getPatientAddressDetailsWorker({ id }) {
  try {
    const res = yield call(getPatientAddressAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_PATIENT_ADDRESS_DETAILS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PATIENT_ADDRESS_DETAILS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* paymentMethodWorker({ id, payload }) {
  try {
    const res = yield call(PaymentMethodAPI, id, payload);
    if (res?.data?.success) {
      yield put({
        type: SUCCESS_PAYMENT_METHOD,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Payment Successfully!",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_PAYMENT_METHOD,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.data?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getPickupCodeWorker({ id }) {
  try {
    const res = yield call(getPickupCodeAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_PICKUP_CODE,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PICKUP_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* setPickupOptionWorker({ id }) {
  try {
    const res = yield call(setPickupOptionAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_PICKUP_OPTION_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Pickup code sent on your email address",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_PICKUP_OPTION_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

export function* PatientViewWatcher() {
  yield takeEvery(GET_PATIENT_VIEW_DETAILS, getPatientWorker);
  yield takeEvery(START_CREATE_DELIVER_ADDRESS, createDeliveryAddressWorker);
  yield takeEvery(START_DELETE_DELIVER_ADDRESS, deleteDeliveryAddressWorker);
  yield takeEvery(START_UPDATE_DELIVER_ADDRESS, updateDeliveryAddressWorker);
  yield takeEvery(
    GET_SINGLE_DELIVER_ADDRESS_BY_ID,
    getSingleDeliveryAddressGetByIdWorker
  );
  yield takeEvery(GET_PATIENT_ADDRESS_DETAILS, getPatientAddressDetailsWorker);
  yield takeEvery(START_PAYMENT_METHOD, paymentMethodWorker);
  yield takeEvery(GET_PICKUP_CODE, getPickupCodeWorker);
  yield takeEvery(SET_PICKUP_OPTION_CODE, setPickupOptionWorker);
}
