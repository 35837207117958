import API from "../Api";
import {
  GET_PATIENT_MANAGEMENT_LIST_ENDPOINT,
  CREATE_PATIENT_MANAGEMENT_ENDPOINT,
  DELETE_PATIENT_MANAGEMENT_ENDPOINT,
  UPDATE_PATIENT_MANAGEMENT_ENDPOINT,
  SINGLE_PATIENT_MANAGEMENT_LIST_GET_BY_ID_ENDPOINT,
} from "./endpoints";
import { generateDynamicEndpoint } from "../utils/generalUtils";

export const getPatientManagementListAPI = (payload) => {
  return API.post(GET_PATIENT_MANAGEMENT_LIST_ENDPOINT, payload);
};

//Create Patient Management
export function createPatientManagementAPI(formData) {
  return API.post(CREATE_PATIENT_MANAGEMENT_ENDPOINT, formData);
}

//Delete Patient Management
export const deletePatientManagementAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_PATIENT_MANAGEMENT_ENDPOINT, { id: id })
  );
};

//Update Patient Management
export const updatePatientManagementAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(UPDATE_PATIENT_MANAGEMENT_ENDPOINT, { id: id }),
    payload
  );
};

//Get Single Patient Management LIst By Id
export const getSingleLiPatientManagementByIdAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(SINGLE_PATIENT_MANAGEMENT_LIST_GET_BY_ID_ENDPOINT, {
      id: id,
    }),
    payload
  );
};
