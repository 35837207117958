import API from "../Api";
import {
  GET_DOCTORS_LIST_ENDPOINT,
  CREATE_DOCTORS_ENDPOINT,
  UPDATE_DOCTORS_ENDPOINT,
  SINGLE_DOCTORS_LIST_GET_BY_ID_ENDPOINT,
  DELETE_DOCTOR_ENDPOINT,
  ASSIGN_OTHER_DOCTOR_ENDPOINT,
  STAFF_AND_PATIENT_COUNT_ENDPOINT,
} from "./endpoints";
import { generateDynamicEndpoint } from "../utils/generalUtils";

//Get Doctor List
export const getDoctorsListAPI = (payload) => {
  return API.post(GET_DOCTORS_LIST_ENDPOINT, payload);
};

//Create Doctor
export function createDoctorAPI(formData) {
  return API.post(CREATE_DOCTORS_ENDPOINT, formData);
}

//Update Doctor
export const updateDoctorAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(UPDATE_DOCTORS_ENDPOINT, { id: id }),
    payload
  );
};

//Get Single Doctor LIst By Id
export const getSingleListByIdAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(SINGLE_DOCTORS_LIST_GET_BY_ID_ENDPOINT, { id: id }),
    payload
  );
};

//Delete Doctor
export const deleteDoctorAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_DOCTOR_ENDPOINT, { id: id })
  );
};

//Assign  Other Doctor
export const AssignOtherDoctorAPI = (formData) => {
  return API.post(ASSIGN_OTHER_DOCTOR_ENDPOINT, formData);
};

//Get Single Doctor LIst By Id
export const StaffAndPatientCountAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(STAFF_AND_PATIENT_COUNT_ENDPOINT, { id: id }),
    payload
  );
};
