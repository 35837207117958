import React from "react";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PrimaryIcon from "../Common/PrimaryIcon";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PageNotFoundIcon } from "../../Assets/PageNotFoundIcon.svg";

/**
 * @author Nikita Mahajan
 * Patient url Not Fount Page
 */

const useStyles = makeStyles((theme) => ({
  rsContainerWrapperRoot: {
    minHeight: "100vh",
    position: "relative",
    background: theme.palette.primary.main,
  },
  pageNotFoundRoot: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column !important",
    textAlign: "center",
    "&>h3": {
      marginBottom: "15px",
    },
    "&>button": {
      marginTop: "15px",
    },
  },
  csBackBtn: {
    color: "black !important",
    marginTop: "-130px !important",
    background: "white !important",
    width: "9% !important",
    height: "60px !important",
    borderRadius: "30px !important",
  },
}));

export default function PatientPublicViewNotFound() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Grid className={classes.rsContainerWrapperRoot}>
      <Grid className={classes.pageNotFoundRoot}>
        <PrimaryIcon color="white" icon={PageNotFoundIcon} />
        <Button
          title="Add Prescription"
          type="submit"
          fullWidth
          className={classes.csBackBtn}
          variant="outlined"
          onClick={() => navigate("/login")}
        >
          Back to Home
        </Button>
      </Grid>
    </Grid>
  );
}
