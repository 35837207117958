//Get Doctors List
export const GET_DOCTORS_LIST = "GET_DOCTORS_LIST";
export const SUCCESS_DOCTORS_LIST = "SUCCESS_DOCTORS_LIST";
export const ERROR_DOCTORS_LIST = "ERROR_DOCTORS_LIST";

//Create Doctors
export const START_CREATE_DOCTORS = "START_CREATE_DOCTORS";
export const SUCCESS_CREATE_DOCTORS = "SUCCESS_CREATE_DOCTORS";
export const ERROR_CREATE_DOCTORS = "ERROR_CREATE_DOCTORS";

//Update Doctors
export const START_UPDATE_DOCTORS = "START_UPDATE_DOCTORS";
export const SUCCESS_UPDATE_DOCTORS = "SUCCESS_UPDATE_DOCTORS";
export const ERROR_UPDATE_DOCTORS = "ERROR_UPDATE_DOCTORS";

//Single Doctors Data Get
export const GET_SINGLE_DOCTORS_LIST_BY_ID = "GET_SINGLE_DOCTORS_LIST_BY_ID";
export const SUCCESS_SINGLE_DOCTORS_LIST_BY_ID =
  "SUCCESS_SINGLE_DOCTORS_LIST_BY_ID";
export const ERROR_SINGLE_DOCTORS_LIST_BY_ID =
  "ERROR_SINGLE_DOCTORS_LIST_BY_ID";

//Delete Doctor
export const START_DELETE_DOCTOR = "START_DELETE_DOCTOR";
export const SUCCESS_DELETE_DOCTOR = "SUCCESS_DELETE_DOCTOR";
export const ERROR_DELETE_DOCTOR = "ERROR_DELETE_DOCTOR";

//Reset Doctor Data State
export const RESET_DOCTOR_STATE = "RESET_DOCTOR_STATE";

//Assign Other Doctor
export const START_ASSIGN_OTHER_DOCTOR = "START_ASSIGN_OTHER_DOCTOR";
export const SUCCESS_ASSIGN_OTHER_DOCTOR = "SUCCESS_ASSIGN_OTHER_DOCTOR";
export const ERROR_ASSIGN_OTHER_DOCTOR = "ERROR_ASSIGN_OTHER_DOCTOR";

//Staff And Doctor COunt
export const START_STAFF_AND_DOCTOR_COUNT = "START_STAFF_AND_DOCTOR_COUNT";
export const SUCCESS_STAFF_AND_DOCTOR_COUNT = "SUCCESS_STAFF_AND_DOCTOR_COUNT";
export const ERROR_STAFF_AND_DOCTOR_COUNT = "ERROR_STAFF_AND_DOCTOR_COUNT";
