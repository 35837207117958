import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_STAFF_MEMBER_LIST,
  SUCCESS_STAFF_MEMBER_LIST,
  ERROR_STAFF_MEMBER_LIST,
  START_CREATE_STAFF_MEMBER,
  SUCCESS_CREATE_STAFF_MEMBER,
  ERROR_CREATE_STAFF_MEMBER,
  START_UPDATE_STAFF_MEMBER,
  SUCCESS_UPDATE_STAFF_MEMBER,
  ERROR_UPDATE_STAFF_MEMBER,
  GET_SINGLE_STAFF_MEMBER_LIST_BY_ID,
  SUCCESS_SINGLE_STAFF_MEMBER_LIST_BY_ID,
  ERROR_SINGLE_STAFF_MEMBER_LIST_BY_ID,
  START_DELETE_STAFF_MEMBER,
  SUCCESS_DELETE_STAFF_MEMBER,
  ERROR_DELETE_STAFF_MEMBER,
  START_REPORTING_PERSON_LIST,
  SUCCESS_REPORTING_PERSON_LIST,
  ERROR_REPORTING_PERSON_LIST,
} from "../Action/StaffMemberAction";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";
import {
  getStaffMemberListAPI,
  createStaffMemberAPI,
  updateStaffMemberAPI,
  getSingleListStaffMemberByIdAPI,
  deleteStaffMemberAPI,
  getReportingPersonListListAPI,
} from "../Api/StaffMemberApis";

function* getStaffMemberListWorker({ payload }) {
  try {
    const res = yield call(getStaffMemberListAPI, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_STAFF_MEMBER_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_STAFF_MEMBER_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createStaffMemberWorker({ formData }) {
  try {
    const res = yield call(createStaffMemberAPI, formData);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_STAFF_MEMBER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Staff member added successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_STAFF_MEMBER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.data.errors[0] || res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateStaffMemberWorker({ id, payload }) {
  try {
    const res = yield call(updateStaffMemberAPI, id, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_UPDATE_STAFF_MEMBER,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Staff member updated successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_STAFF_MEMBER,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getSingleStaffMemberListGetByIdWorker({ id }) {
  try {
    const res = yield call(getSingleListStaffMemberByIdAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_SINGLE_STAFF_MEMBER_LIST_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SINGLE_STAFF_MEMBER_LIST_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deleteStaffMemberWorker({ id }) {
  try {
    const res = yield call(deleteStaffMemberAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_DELETE_STAFF_MEMBER,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Staff member deleted successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_STAFF_MEMBER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.errors.id[0],
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getReportingPersonWorker({ payload }) {
  try {
    const res = yield call(getReportingPersonListListAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_REPORTING_PERSON_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_REPORTING_PERSON_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* StaffMemberWatcher() {
  yield takeEvery(GET_STAFF_MEMBER_LIST, getStaffMemberListWorker);
  yield takeEvery(START_CREATE_STAFF_MEMBER, createStaffMemberWorker);
  yield takeEvery(START_UPDATE_STAFF_MEMBER, updateStaffMemberWorker);
  yield takeEvery(START_DELETE_STAFF_MEMBER, deleteStaffMemberWorker);
  yield takeEvery(START_REPORTING_PERSON_LIST, getReportingPersonWorker);
  yield takeEvery(
    GET_SINGLE_STAFF_MEMBER_LIST_BY_ID,
    getSingleStaffMemberListGetByIdWorker
  );
}
