import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import ClinicDetails from "./ClinicDetails";
import { useNavigate } from "react-router-dom";
import useViewport from "../../Hooks/useViewport";
import ClinicLeadDetails from "./ClinicLeadDetails";
import AuthButton from "../../Components/Common/AuthButton";
import PrimaryIcon from "../../Components/Common/PrimaryIcon";
import CarouselAuth from "../../Components/Common/CarouselAuth";
import { RESET_STATE, START_SIGN_UP } from "../../Action/AuthAction";
import { ReactComponent as mobileLogo } from "../../Assets/mobileLogo.svg";
import { Box, Grid, Button, Typography, CssBaseline } from "@mui/material";

const useStyle = makeStyles((theme) => ({
  cSMainHeading: {
    color: `${theme.palette.background.blackText} !important`,
    marginBottom: "20px !important",
    textAlign: "center",
    fontSize: "18px !important",
  },
  cSMainLogo: {
    display: "none",
    [theme.breakpoints.down("1300")]: {
      textAlign: "center",
      marginBottom: "50px",
      display: "block",
    },
  },
  cSMainGrid: {
    [theme.breakpoints.down("1300")]: {
      margin: "0 auto !important",
    },
  },
  csSubmit: {
    marginTop: "-86px !important",
  },
  cSBackText: {
    marginTop: "25px !important",
    cursor: "pointer",
    "&>h4": {
      fontSize: "16px !important",
      color: `${theme.palette.primary.main} !important`,
    },
  },
  csNext: {
    marginTop: "31px !important",
    "&>button": {
      padding: "28px 75px !important",
    },
  },
}));

function SignUp({ dispatchSignUp, signUpDetails, dispatchResetState }) {
  const classes = useStyle();
  const { width } = useViewport();
  const navigate = useNavigate();
  const [signUpData, setSignUpData] = useState({
    title: "",
    personal_mobile_number: "",
    first_name: "",
    last_name: "",
    personal_email: "",
    name_of_clinic: "",
    address_line_one: "",
    city: "",
    postcode: "",
    clinic_phone_number: "",
    clinic_email: "",
    website: "",
    cqc_registration_number: "",
    registered_manager_name: "",
    registered_manager_email: "",
    nominated_individual_name: "",
    nominated_individual_email: "",
  });
  const [openSecondStepFrom, setOpenSecondStepFrom] = useState(false);

  /* Handle Submit Function */
  const handleSubmitClinicOwner = async (formValues) => {
    setOpenSecondStepFrom(true);
    const formData = new FormData();
    formData.append("title", formValues?.title);
    formData.append("first_name", formValues?.first_name);
    formData.append("last_name", formValues?.last_name);
    formData.append(
      "personal_mobile_number",
      formValues?.personal_mobile_number
    );
    formData.append("personal_email", formValues?.personal_email);
    formData.append("name_of_clinic", formValues?.name_of_clinic);
    formData.append("city", formValues?.city);
    formData.append("postcode", formValues?.postcode);
    formData.append("address_line_one", formValues?.address_line_one);
    formData.append("clinic_phone_number", formValues?.clinic_phone_number);
    formData.append("clinic_email", formValues?.clinic_email);
    formData.append("website", formValues?.website);
    formData.append(
      "cqc_registration_number",
      formValues?.cqc_registration_number
    );
    formData.append(
      "registered_manager_name",
      formValues?.registered_manager_name
    );
    formData.append(
      "registered_manager_email",
      formValues?.registered_manager_email
    );
    formData.append(
      "nominated_individual_name",
      formValues?.nominated_individual_name
    );
    formData.append(
      "nominated_individual_email",
      formValues?.nominated_individual_email
    );
    if (openSecondStepFrom) {
      dispatchSignUp(formData);
    }
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const firstFromSchema = yup.object().shape({
    title: yup.string().required("The title field is required"),
    first_name: yup
      .string()
      .required("The first name field is required")
      .matches(/^[A-Za-z ]*$/, "The numbers are not allowed in the first name"),
    last_name: yup
      .string()
      .required("The last name field is required")
      .matches(/^[A-Za-z ]*$/, "The numbers are not allowed in the last name"),
    personal_mobile_number: yup
      .string()
      .required("The personal mobile number field is required")
      .matches(phoneRegExp, "The personal mobile  number is not valid")
      .max(12, "The  personal mobile number should be between 10 to 12 digits.")
      .min(
        10,
        "The  personal mobile number should be between 10 to 12 digits."
      ),
    personal_email: yup
      .string()
      .email("Please enter valid email address")
      .required("The personal Email field is required"),
  });

  /* Schema  */
  const setClinicOwnerSchema = yup.object().shape({
    name_of_clinic: yup
      .string()
      // .required("The name of clinic field is required")
      .matches(
        /^[A-Za-z ]*$/,
        "The numbers are not allowed in the clinic name"
      ),
    clinic_phone_number: yup
      .string()
      // .required("The clinic phone  number field is required.")
      .matches(phoneRegExp, "The person mobile  number is not valid")
      .max(12, "The  person mobile number should be between 10 to 12 digits.")
      .min(10, "The  person mobile number should be between 10 to 12 digits."),
    clinic_email: yup.string().email("Please enter valid email address"),
    // .required("The person clinic email field is required"),
  });

  /* Formik */
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues: signUpData,
    validationSchema: !openSecondStepFrom
      ? firstFromSchema
      : setClinicOwnerSchema,
    onSubmit: handleSubmitClinicOwner,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (signUpDetails?.data?.data?.success === true) {
      dispatchResetState("signUpDetails", "");
      navigate("/login");
      setOpenSecondStepFrom(false);
      setSignUpData("");
      resetForm();
    }
  }, [signUpDetails?.data?.data?.success]);

  // //Server Side Validation Set
  useEffect(() => {
    const errors = signUpDetails?.error?.payload?.errors;

    if (errors && Object?.keys(errors)?.length > 0) {
      let obj = {};
      Object.entries(errors)?.map(([item, value]) => {
        obj[item] = value[0];
        return item;
      });
      setErrors(obj);
    }
  }, [signUpDetails?.error?.payload?.errors]);

  useEffect(() => {
    const errors = signUpDetails?.error?.payload?.error;

    if (errors && Object?.keys(errors)?.length > 0) {
      let obj = {};
      Object.entries(errors)?.map(([item, value]) => {
        obj[item] = value[0];
        return item;
      });
      setErrors(obj);
    }
  }, [signUpDetails?.error?.payload?.error]);

  const renderClinicOwnerForm = () => {
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          autoComplete="off"
          sx={{ mt: 1 }}
        >
          {openSecondStepFrom ? (
            <ClinicDetails
              errors={errors}
              values={values}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setOpenSecondStepFrom={setOpenSecondStepFrom}
            />
          ) : (
            <ClinicLeadDetails
              width={width}
              errors={errors}
              values={values}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
          {openSecondStepFrom ? (
            <Box className={classes.csSubmit}>
              <AuthButton
                children={"Submit"}
                disabled={signUpDetails?.isLoading}
                loading={signUpDetails?.isLoading}
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                className={classes.cSBackText}
                onClick={() => {
                  console.log(values, "values");
                  setOpenSecondStepFrom(false);
                  if (!openSecondStepFrom) {
                    setOpenSecondStepFrom({
                      title: values?.title,
                      first_name: values?.first_name,
                      last_name: values?.last_name,
                      personal_mobile_number: values?.personal_mobile_number,
                      personal_email: values?.personal_email,
                    });
                  }
                }}
              >
                <Typography variant="h4" bold>
                  Back
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              className={classes.csNext}
            >
              <Button variant="contained" type="submit">
                Next
              </Button>
            </Box>
          )}
        </form>
      </>
    );
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <CarouselAuth />
      <Grid item xs={12} sm={8} md={6} lg={6} className={classes.cSMainGrid}>
        <Box
          sx={{
            my: width < 1400 ? 10 : openSecondStepFrom ? 4 : 20,
            mx: width < 1600 ? 5 : 15,
          }}
        >
          <Box className={classes.cSMainLogo}>
            <PrimaryIcon color="white" icon={mobileLogo} />
          </Box>
          <Box>
            <Typography bold className={classes.cSMainHeading}>
              Please fill the details below to submit your Clinic Registration
              with Clinly
            </Typography>
          </Box>
          {renderClinicOwnerForm()}
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  signUpDetails: state.auth.signUpDetails,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchSignUp: (formData) => dispatch({ type: START_SIGN_UP, formData }),
  dispatchResetState: (key, payload) =>
    dispatch({ type: RESET_STATE, key, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
