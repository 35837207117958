import {
  GET_USER_DETAIL,
  SUCCESS_USER_DETAIL,
  ERROR_USER_DETAIL,
  GET_AFTER_LOGIN_USER_DETAIL,
  SUCCESS_AFTER_LOGIN_USER_DETAIL,
  ERROR_AFTER_LOGIN_USER_DETAIL,
} from "../Action/UserAction";

const initialState = {
  setLoginPasswordUserDetails: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  getAfterLoginUserDetails: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
};

const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAIL: {
      return {
        ...state,
        setLoginPasswordUserDetails: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_USER_DETAIL: {
      return {
        ...state,
        setLoginPasswordUserDetails: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_USER_DETAIL: {
      return {
        ...state,
        setLoginPasswordUserDetails: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_AFTER_LOGIN_USER_DETAIL: {
      return {
        ...state,
        getAfterLoginUserDetails: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_AFTER_LOGIN_USER_DETAIL: {
      return {
        ...state,
        getAfterLoginUserDetails: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_AFTER_LOGIN_USER_DETAIL: {
      return {
        ...state,
        getAfterLoginUserDetails: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default UserReducers;
