import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  START_SET_LOGIN_PASSWORD,
  SUCCESS_SET_LOGIN_PASSWORD,
  ERROR_SET_LOGIN_PASSWORD,
  START_LOGIN,
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  START_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  START_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  ERROR_RESET_PASSWORD,
  START_SET_LOGIN_VERIFY_LINK,
  SUCCESS_SET_LOGIN_VERIFY_LINK,
  ERROR_SET_LOGIN_VERIFY_LINK,
  START_SIGN_UP,
  SUCCESS_SIGN_UP,
  ERROR_SIGN_UP,
} from "../Action/AuthAction";
import API from "../Api";
import {
  setLoginPasswordAPI,
  loginAPI,
  logoutUserAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  getSetLoginVerifyLinkAPI,
  signUpAPI,
} from "../Api/AuthApis";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";
import { logoutUser } from "../utils/generalUtils";

function* SetLoginPasswordWorker({ formData, token }) {
  try {
    const response = yield call(setLoginPasswordAPI, formData, token);
    if (response.success) {
      API.setAccessTokenInLocalStorage(response.data.token);
      yield put({
        type: SUCCESS_SET_LOGIN_PASSWORD,
        data: response,
        token: response.data.token,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Password set successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_SET_LOGIN_PASSWORD,
        data: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* LoginWorker({ formData }) {
  try {
    const response = yield call(loginAPI, formData);
    if (response.success) {
      API.setAccessTokenInLocalStorage(response.data.token);
      yield put({
        type: SUCCESS_LOGIN,
        data: response,
        token: response.data.token,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Login successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_LOGIN,
        data: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* LogoutWorker() {
  try {
    const response = yield call(logoutUserAPI);
    if (response?.success) {
      yield put({
        type: LOGOUT_SUCCESS,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Logout successfully.",
          type: "success",
        },
      });
      logoutUser();
    } else {
      yield put({
        type: LOGOUT_ERROR,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Something went wrong! Please try again later.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* forgotPasswordWorker({ formData }) {
  try {
    const response = yield call(forgotPasswordAPI, formData);
    if (response.success) {
      yield put({
        type: SUCCESS_FORGOT_PASSWORD,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Reset password link sent on your email id.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_FORGOT_PASSWORD,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* resetPasswordWorker({ formData }) {
  try {
    const response = yield call(resetPasswordAPI, formData);
    if (response.success) {
      API.setAccessTokenInLocalStorage(response.data.token);
      yield put({
        type: SUCCESS_RESET_PASSWORD,
        data: response,
        token: response.data.token,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Password has been updated successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_RESET_PASSWORD,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getSetLoginVerifyLinkWorker({ id }) {
  try {
    const res = yield call(getSetLoginVerifyLinkAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_SET_LOGIN_VERIFY_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SET_LOGIN_VERIFY_LINK,
        payload: res,
      });
    }
  } catch (error) {}
}

function* signUpWorker({ formData }) {
  try {
    const response = yield call(signUpAPI, formData);
    if (response.success) {
      yield put({
        type: SUCCESS_SIGN_UP,
        data: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Clinic Registration request sent successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_SIGN_UP,
        payload: response,
      });
      // yield put({
      //   type: ADD_NEW_NOTIFICATION,
      //   payload: {
      //     message: response.message,
      //     type: "error",
      //   },
      // });
    }
  } catch (error) {}
}

export function* AuthWatcher() {
  yield takeEvery(START_SET_LOGIN_PASSWORD, SetLoginPasswordWorker);
  yield takeEvery(START_LOGIN, LoginWorker);
  yield takeEvery(LOGOUT_START, LogoutWorker);
  yield takeEvery(START_FORGOT_PASSWORD, forgotPasswordWorker);
  yield takeEvery(START_RESET_PASSWORD, resetPasswordWorker);
  yield takeEvery(START_SET_LOGIN_VERIFY_LINK, getSetLoginVerifyLinkWorker);
  yield takeEvery(START_SIGN_UP, signUpWorker);
}
