import React from "react";
import PrimaryIcon from "./PrimaryIcon";
import { makeStyles } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import { Typography, Box, Grid } from "@mui/material";
import { ReactComponent as CarouselOne } from "../../Assets/CarouselOne.svg";
import { ReactComponent as CarouselTwo } from "../../Assets/CarouselTwo.svg";
import { ReactComponent as CarouselFour } from "../../Assets/CarouselFour.svg";
import { ReactComponent as CarouselThree } from "../../Assets/CarouselThree.svg";
import { ReactComponent as ClinlyCarouselLogo } from "../../Assets/ClinlyCarouselLogo.svg";

const useStyle = makeStyles((theme) => ({
  csCarouselMain: {
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("1281")]: {
      display: "none",
    },
  },
  csMainLogo: {
    "&>svg": {
      width: "200px",
      padding: "10px",
      height: "84px",
      marginTop: "12px",
    },
  },
  csCenterImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  csCenterText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&>h3": {
      color: theme.palette.white.main,
    },
  },
  csSubTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>span": {
      color: theme.palette.background.default,
      fontSize: "14px",
      opacity: 0.7,
    },
  },
}));

export default function CarouselAuth() {
  const classes = useStyle();
  const sliderImages = [
    {
      name: "Customizable Multipurpose Dashboard",
      subTitle: "Everything you need in an easily customizable dashboard.",
      src: <PrimaryIcon color="white" icon={CarouselOne} />,
    },
    {
      name: "Customizable Multipurpose Dashboard",
      subTitle: "Everything you need in an easily customizable dashboard.",
      src: <PrimaryIcon color="white" icon={CarouselTwo} />,
    },
    {
      name: "Customizable Multipurpose Dashboard",
      subTitle: "Everything you need in an easily customizable dashboard.",
      src: <PrimaryIcon color="white" icon={CarouselThree} />,
    },
    {
      name: "Customizable Multipurpose Dashboard",
      subTitle: "Everything you need in an easily customizable dashboard.",
      src: <PrimaryIcon color="white" icon={CarouselFour} />,
    },
  ];

  return (
    <Grid
      item
      xs={false}
      sm={4}
      md={6}
      lg={6}
      className={classes.csCarouselMain}
    >
      <Box className={classes.csMainLogo}>
        <PrimaryIcon color="white" icon={ClinlyCarouselLogo} />
      </Box>
      <Carousel
        indicatorIconButtonProps={{
          style: {
            color: "#FFF",
            opacity: "0.2",
            marginLeft: "16px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#FFF",
            opacity: "1",
            marginLeft: "16px",
          },
        }}
      >
        {sliderImages.map((item, i) => (
          <>
            <Box key={i} className={classes.csCenterImage}>
              {item?.src}
            </Box>
            <Box className={classes.csCenterText}>
              <Typography variant="h3" bold>
                {item?.name}
              </Typography>
            </Box>
            <Box className={classes.csSubTitle}>
              <span>{item?.subTitle}</span>
            </Box>
          </>
        ))}
      </Carousel>
    </Grid>
  );
}
