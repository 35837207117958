//Set Login Password Actions
export const START_SET_LOGIN_PASSWORD = "START_SET_LOGIN_PASSWORD";
export const SUCCESS_SET_LOGIN_PASSWORD = "SUCCESS_SET_LOGIN_PASSWORD";
export const ERROR_SET_LOGIN_PASSWORD = "ERROR_SET_LOGIN_PASSWORD";

//Login Actions
export const START_LOGIN = "START_LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";

//Logout
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

//Rest InitialState Actions
export const RESET_STATE = "RESET_STATE";

//Forgot Password Actions
export const START_FORGOT_PASSWORD = "START_FORGOT_PASSWORD";
export const SUCCESS_FORGOT_PASSWORD = "SUCCESS_FORGOT_PASSWORD";
export const ERROR_FORGOT_PASSWORD = "ERROR_FORGOT_PASSWORD";

//Reset Password Actions
export const START_RESET_PASSWORD = "START_RESET_PASSWORD";
export const SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD";
export const ERROR_RESET_PASSWORD = "ERROR_RESET_PASSWORD";

//Set Login Verify Link Actions
export const START_SET_LOGIN_VERIFY_LINK = "START_SET_LOGIN_VERIFY_LINK";
export const SUCCESS_SET_LOGIN_VERIFY_LINK = "SUCCESS_SET_LOGIN_VERIFY_LINK";
export const ERROR_SET_LOGIN_VERIFY_LINK = "ERROR_SET_LOGIN_VERIFY_LINK";

//Sign up Actions
export const START_SIGN_UP = "START_SIGN_UP";
export const SUCCESS_SIGN_UP = "SUCCESS_SIGN_UP";
export const ERROR_SIGN_UP = "ERROR_SIGN_UP";
