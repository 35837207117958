import { legacy_createStore as createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import RootSaga from "./Sagas";
import Reducers from "./Reducers";
import { applyMiddleware } from "redux";

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  Reducers,
  //composeWithDevTools(applyMiddleware(sagaMiddleware))

  compose(
    applyMiddleware(sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
sagaMiddleware.run(RootSaga);
