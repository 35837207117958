import React from "react";
import useViewport from "../../Hooks/useViewport";
import PrimaryIcon from "../../Components/Common/PrimaryIcon";
import { Grid, TextField, InputAdornment } from "@mui/material";
import { ReactComponent as CityIcon } from "../../Assets/CityIcon.svg";
import { ReactComponent as userIcon } from "../../Assets/userIcon.svg";
import { ReactComponent as MailIcon } from "../../Assets/MailIcon.svg";
import { ReactComponent as PhoneIcon } from "../../Assets/PhoneIcon.svg";
import { ReactComponent as ManagerIcon } from "../../Assets/ManagerIcon.svg";
import { ReactComponent as WebSiteIcon } from "../../Assets/WebSiteIcon.svg";
import { ReactComponent as AddressIcon } from "../../Assets/AddressIcon.svg";
import { ReactComponent as PostCodeIcon } from "../../Assets/PostCodeIcon.svg";
import { ReactComponent as IndividualNameIcon } from "../../Assets/IndividualNameIcon.svg";
import { ReactComponent as IndividualEmailIcon } from "../../Assets/IndividualEmailIcon.svg";

export default function ClinicDetails({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
}) {
  const { width } = useViewport();
  return (
    <Grid container columnSpacing={1} rowSpacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <TextField
          margin="normal"
          fullWidth
          value={values.name_of_clinic}
          name="name_of_clinic"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          placeholder="Name Of Clinic *"
          type="name_of_clinic"
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={userIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.name_of_clinic && errors.name_of_clinic}
          helperText={touched.name_of_clinic && errors.name_of_clinic}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <TextField
          margin="normal"
          fullWidth
          value={values.address_line_one}
          name="address_line_one"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          placeholder="Address Line 1"
          type="address_line_one"
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={AddressIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.address_line_one && errors.address_line_one}
          helperText={touched.address_line_one && errors.address_line_one}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <TextField
          margin="normal"
          fullWidth
          value={values.city}
          name="city"
          placeholder="City"
          type="city"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={CityIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.city && errors.city}
          helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <TextField
          margin="normal"
          fullWidth
          value={values.postcode}
          name="postcode"
          placeholder="Post Code"
          type="postcode"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={PostCodeIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.postcode && errors.postcode}
          helperText={touched.postcode && errors.postcode}
        />
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={6}>
        <TextField
          margin="normal"
          fullWidth
          value={values.clinic_phone_number}
          name="clinic_phone_number"
          placeholder="Clinic Phone Number *"
          type="clinic_phone_number"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={PhoneIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.clinic_phone_number && errors.clinic_phone_number}
          helperText={touched.clinic_phone_number && errors.clinic_phone_number}
        />
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={6}>
        <TextField
          margin="normal"
          fullWidth
          value={values.clinic_email}
          name="clinic_email"
          placeholder="Clinic Email Address*"
          type="clinic_email"
          // onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={MailIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.clinic_email && errors.clinic_email}
          helperText={touched.clinic_email && errors.clinic_email}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={12}
        md={width > 1300 ? 6 : 12}
        lg={width > 1300 ? 6 : 12}
      >
        <TextField
          margin="normal"
          fullWidth
          value={values.website}
          name="website"
          placeholder="Clinic Website"
          type="website"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={WebSiteIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={touched.website && errors.website}
          helperText={touched.website && errors.website}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={12}
        md={width > 1300 ? 6 : 12}
        lg={width > 1300 ? 6 : 12}
      >
        <TextField
          margin="normal"
          fullWidth
          value={values.cqc_registration_number}
          name="cqc_registration_number"
          placeholder="CQC Registration Number"
          type="cqc_registration_number"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={MailIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={
            touched.cqc_registration_number && errors.cqc_registration_number
          }
          helperText={
            touched.cqc_registration_number && errors.cqc_registration_number
          }
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={12}
        md={width > 1300 ? 6 : 12}
        lg={width > 1300 ? 6 : 12}
      >
        <TextField
          margin="normal"
          fullWidth
          value={values.registered_manager_name}
          name="registered_manager_name"
          placeholder="Registered Manager Name"
          type="registered_manager_name"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon
                    color="#8A949B"
                    width="24px"
                    icon={ManagerIcon}
                  />
                </InputAdornment>
              </>
            ),
          }}
          error={
            touched.registered_manager_name && errors.registered_manager_name
          }
          helperText={
            touched.registered_manager_name && errors.registered_manager_name
          }
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={12}
        md={width > 1300 ? 6 : 12}
        lg={width > 1300 ? 6 : 12}
      >
        <TextField
          margin="normal"
          fullWidth
          value={values.registered_manager_email}
          name="registered_manager_email"
          placeholder="Registered Manager Email Address"
          type="registered_manager_email"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={MailIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={
            touched.registered_manager_email && errors.registered_manager_email
          }
          helperText={
            touched.registered_manager_email && errors.registered_manager_email
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          margin="normal"
          fullWidth
          value={values.nominated_individual_name}
          name="nominated_individual_name"
          placeholder="Nominated Individual Name"
          type="nominated_individual_name"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={IndividualNameIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={
            touched.nominated_individual_name &&
            errors.nominated_individual_name
          }
          helperText={
            touched.nominated_individual_name &&
            errors.nominated_individual_name
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          margin="normal"
          fullWidth
          value={values.nominated_individual_email}
          name="nominated_individual_email"
          placeholder="Nominated Individual Email Address"
          type="nominated_individual_email"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: width < 1401 ? "13px" : "16px" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PrimaryIcon width="24px" icon={IndividualEmailIcon} />
                </InputAdornment>
              </>
            ),
          }}
          error={
            touched.nominated_individual_email &&
            errors.nominated_individual_email
          }
          helperText={
            touched.nominated_individual_email &&
            errors.nominated_individual_email
          }
        />
      </Grid>
    </Grid>
  );
}
