import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_DOCTORS_LIST,
  SUCCESS_DOCTORS_LIST,
  ERROR_DOCTORS_LIST,
  START_CREATE_DOCTORS,
  SUCCESS_CREATE_DOCTORS,
  ERROR_CREATE_DOCTORS,
  GET_SINGLE_DOCTORS_LIST_BY_ID,
  SUCCESS_SINGLE_DOCTORS_LIST_BY_ID,
  ERROR_SINGLE_DOCTORS_LIST_BY_ID,
  START_UPDATE_DOCTORS,
  SUCCESS_UPDATE_DOCTORS,
  ERROR_UPDATE_DOCTORS,
  START_DELETE_DOCTOR,
  SUCCESS_DELETE_DOCTOR,
  ERROR_DELETE_DOCTOR,
  START_ASSIGN_OTHER_DOCTOR,
  SUCCESS_ASSIGN_OTHER_DOCTOR,
  ERROR_ASSIGN_OTHER_DOCTOR,
  START_STAFF_AND_DOCTOR_COUNT,
  SUCCESS_STAFF_AND_DOCTOR_COUNT,
  ERROR_STAFF_AND_DOCTOR_COUNT,
} from "../Action/DoctorsAction";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";
import {
  getDoctorsListAPI,
  createDoctorAPI,
  getSingleListByIdAPI,
  updateDoctorAPI,
  deleteDoctorAPI,
  AssignOtherDoctorAPI,
  StaffAndPatientCountAPI,
} from "../Api/DoctorsApis";

function* getDoctorsListWorker({ payload }) {
  try {
    const res = yield call(getDoctorsListAPI, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_DOCTORS_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_DOCTORS_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createDoctorWorker({ formData }) {
  try {
    const res = yield call(createDoctorAPI, formData);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_DOCTORS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_DOCTORS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.data.errors[0] || res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getSingleDoctorListGetByIdWorker({ id }) {
  try {
    const res = yield call(getSingleListByIdAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_SINGLE_DOCTORS_LIST_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SINGLE_DOCTORS_LIST_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateDoctorWorker({ id, payload }) {
  try {
    const res = yield call(updateDoctorAPI, id, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_UPDATE_DOCTORS,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Doctor updated successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_DOCTORS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deleteDoctorWorker({ id }) {
  try {
    const res = yield call(deleteDoctorAPI, id);
    if (res.success) {
      yield put({
        type: SUCCESS_DELETE_DOCTOR,
        payload: res,
        id: id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Doctor deleted successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_DOCTOR,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.errors.id[0],
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getStaffAndWorker({ id }) {
  try {
    const res = yield call(StaffAndPatientCountAPI, id);
    if (res?.success) {
      yield put({
        type: SUCCESS_STAFF_AND_DOCTOR_COUNT,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_STAFF_AND_DOCTOR_COUNT,
        payload: res,
      });
    }
  } catch (error) {}
}

function* assignToOtherDoctorWorker({ formData }) {
  try {
    const res = yield call(AssignOtherDoctorAPI, formData);
    if (res.success) {
      yield put({
        type: SUCCESS_ASSIGN_OTHER_DOCTOR,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
        },
      });
    } else {
      yield put({
        type: ERROR_ASSIGN_OTHER_DOCTOR,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

export function* DoctorsWatcher() {
  yield takeEvery(GET_DOCTORS_LIST, getDoctorsListWorker);
  yield takeEvery(START_CREATE_DOCTORS, createDoctorWorker);
  yield takeEvery(START_UPDATE_DOCTORS, updateDoctorWorker);
  yield takeEvery(START_DELETE_DOCTOR, deleteDoctorWorker);
  yield takeEvery(
    GET_SINGLE_DOCTORS_LIST_BY_ID,
    getSingleDoctorListGetByIdWorker
  );
  yield takeEvery(START_STAFF_AND_DOCTOR_COUNT, getStaffAndWorker);
  yield takeEvery(START_ASSIGN_OTHER_DOCTOR, assignToOtherDoctorWorker);
}
