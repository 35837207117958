import {
  START_VERIFY_PICKUP_CODE,
  SUCCESS_VERIFY_PICKUP_CODE,
  ERROR_VERIFY_PICKUP_CODE,
  START_CREATE_PHARMACY_DETAILS,
  SUCCESS_CREATE_PHARMACY_DETAILS,
  ERROR_CREATE_PHARMACY_DETAILS,
} from "../Action/VerifyPrescriptionAction";

const initialState = {
  verifyPickupCodeDetails: {
    loading: false,
    data: {},
    error: {},
  },
  createPharmacyDetails: {
    loading: false,
    data: [],
    error: {},
  },
};

const VerifyPickupCodeReducers = (state = initialState, action) => {
  switch (action.type) {
    case START_VERIFY_PICKUP_CODE: {
      return {
        ...state,
        verifyPickupCodeDetails: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_VERIFY_PICKUP_CODE: {
      return {
        ...state,
        verifyPickupCodeDetails: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_VERIFY_PICKUP_CODE: {
      return {
        ...state,
        verifyPickupCodeDetails: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_PHARMACY_DETAILS: {
      return {
        ...state,
        createPharmacyDetails: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_PHARMACY_DETAILS: {
      return {
        ...state,
        createPharmacyDetails: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_CREATE_PHARMACY_DETAILS: {
      return {
        ...state,
        createPharmacyDetails: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default VerifyPickupCodeReducers;
