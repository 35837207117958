import { createTheme } from "@mui/material/styles";
import {
  MuiButton,
  MuiTypography,
  MuiAvatar,
  MuiLink,
  MuiTextField,
  MuiDivider,
  MuiDialog,
  MuiTable,
  MuiFormControl,
  MuiAutocomplete,
  MuiChip,
  MuiSelect,
} from "./ThemeComponents";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1CA8EA",
    },
    secondary: {
      main: "#5A6872",
    },
    text: {
      textColor: "#2563EB",
    },
    active: {
      main: "#E9514E",
    },
    background: {
      paper: "#ffffff",
      default: "#F8FAFC",
      grey: "#F7F7F8",
      red: "#E9514E",
      black: "#000000",
      field: "#ECECEC",
      blackText: "#0F172A",
      iconColor: "#64748B",
      lightGrayColor: "#F1F5F9",
      redLight: "#FE236F",
      tableBorder: "#EEE",
      tableTextColor: "#94A3B8",
      buttonBorder: "#E2E8F0",
      deleteText: "#E54C42",
      deleteBtnBackground: "#FF4A3F",
      tableBackground: "#F8F8F8",
      plainText: "#979797",
      buttonColor: "#29CB7A",
      green: "#2DBC58",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#0F172A",
    },
    error: {
      main: "#e9514e",
      background: "#FA6F6F",
    },
  },
  typography: {
    fontFamily: "Sora, sans-serif",
  },
  components: {
    MuiTypography: {
      ...MuiTypography,
    },
    MuiButton: {
      ...MuiButton,
    },
    MuiLink: {
      ...MuiLink,
    },
    MuiChip: {
      ...MuiChip,
    },
    MuiAvatar: {
      ...MuiAvatar,
    },
    MuiTextField: {
      ...MuiTextField,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px !important",
        },
        shrink: {
          transform: "translate(12px, -8px) scale(0.88)  !important",
        },
        outlined: {
          transform: "translate(14px, 20px) scale(1)",
        },
      },
    },
    MuiFormControl: {
      ...MuiFormControl,
    },
    MuiDivider: {
      ...MuiDivider,
    },
    MuiAutocomplete: {
      ...MuiAutocomplete,
    },
    MuiFormControlLabel: {
      root: {
        whiteSpace: "nowrap",
      },
    },
    ...MuiTable,
    ...MuiDialog,
    MuiSelect: MuiSelect,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
