//Get Staff List
export const GET_STAFF_MEMBER_LIST = "GET_STAFF_MEMBER_LIST";
export const SUCCESS_STAFF_MEMBER_LIST = "SUCCESS_STAFF_MEMBER_LIST";
export const ERROR_STAFF_MEMBER_LIST = "ERROR_STAFF_MEMBER_LIST";

//Create Staff Member
export const START_CREATE_STAFF_MEMBER = "START_CREATE_STAFF_MEMBER";
export const SUCCESS_CREATE_STAFF_MEMBER = "SUCCESS_CREATE_STAFF_MEMBER";
export const ERROR_CREATE_STAFF_MEMBER = "ERROR_CREATE_STAFF_MEMBER";

//Update Doctors
export const START_UPDATE_STAFF_MEMBER = "START_UPDATE_STAFF_MEMBER";
export const SUCCESS_UPDATE_STAFF_MEMBER = "SUCCESS_UPDATE_STAFF_MEMBER";
export const ERROR_UPDATE_STAFF_MEMBER = "ERROR_UPDATE_STAFF_MEMBER";

//Single Staff Member Data Get
export const GET_SINGLE_STAFF_MEMBER_LIST_BY_ID =
  "GET_SINGLE_STAFF_MEMBER_LIST_BY_ID";
export const SUCCESS_SINGLE_STAFF_MEMBER_LIST_BY_ID =
  "SUCCESS_SINGLE_STAFF_MEMBER_LIST_BY_ID";
export const ERROR_SINGLE_STAFF_MEMBER_LIST_BY_ID =
  "ERROR_SINGLE_STAFF_MEMBER_LIST_BY_ID";

//Delete Staff Member
export const START_DELETE_STAFF_MEMBER = "START_DELETE_STAFF_MEMBER";
export const SUCCESS_DELETE_STAFF_MEMBER = "SUCCESS_DELETE_STAFF_MEMBER";
export const ERROR_DELETE_STAFF_MEMBER = "ERROR_DELETE_STAFF_MEMBER";

//Reset Staff Member Data State
export const RESET_STAFF_MEMBER = "RESET_STAFF_MEMBER";

//Reporting Person List
export const START_REPORTING_PERSON_LIST = "START_REPORTING_PERSON_LIST";
export const SUCCESS_REPORTING_PERSON_LIST = "SUCCESS_REPORTING_PERSON_LIST";
export const ERROR_REPORTING_PERSON_LIST = "ERROR_REPORTING_PERSON_LIST";
