import React, { useState, useEffect } from "react";
import {
  Grid,
  CssBaseline,
  TextField,
  Typography,
  InputAdornment,
  Box,
  Checkbox,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { AI_LOGIN } from "../../Api/AuthApis";
import { useNavigate } from "react-router-dom";
import useViewport from "../../Hooks/useViewport";
import { rememberMeFunc } from "utils/generalUtils";
import AuthButton from "../../Components/Common/AuthButton";
import PrimaryIcon from "../../Components/Common/PrimaryIcon";
import CarouselAuth from "../../Components/Common/CarouselAuth";
import { START_LOGIN, RESET_STATE } from "../../Action/AuthAction";
import { ReactComponent as eyeIcon } from "../../Assets/eyeIcon.svg";
import { GET_AFTER_LOGIN_USER_DETAIL } from "../../Action/UserAction";
import { ReactComponent as LockIcon } from "../../Assets/LockIcon.svg";
import { ReactComponent as EmailIcon } from "../../Assets/EmailIcon.svg";
import { ReactComponent as mobileLogo } from "../../Assets/mobileLogo.svg";
import { ReactComponent as crossedEyeIcon } from "../../Assets/crossedEyeIcon.svg";

const useStyle = makeStyles((theme) => ({
  cSPassword: {
    left: "15px !important",
    "&>svg": {
      height: "23px",
      width: "23px",
    },
  },
  cSMainHeading: {
    color: `${theme.palette.background.blackText} !important`,
    letterSpacing: "0.2px !important",
    marginBottom: "10px !important",
  },
  cSRemember: {
    marginTop: "20px !important",
    marginLeft: "0px !important",
    "&>span": {
      fontWeight: 400,
      fontStyle: "normal",
      letterSpacing: "0.2px !important",
    },
  },
  cSForgot: {
    marginTop: "29px !important",
    "&>h4": {
      color: `${theme.palette.text.textColor} !important`,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
  },
  cSMainLogo: {
    display: "none",
    [theme.breakpoints.down("1300")]: {
      textAlign: "center",
      marginBottom: "50px",
      display: "block",
    },
  },
  cSMainGrid: {
    [theme.breakpoints.down("1300")]: {
      margin: "0 auto !important",
    },
  },
}));

function Login({
  dispatchLogin,
  loginDetails,
  dispatchResetState,
  getAfterLoginUserDetailAction,
}) {
  const rememberMeInstance = new rememberMeFunc();

  const rememberData = rememberMeInstance.get();

  const classes = useStyle();
  const { width } = useViewport();
  const navigate = useNavigate();
  const [isLoginProceed, setIsLoginProceed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    Boolean(Object.keys(rememberData)?.length)
  );
  const handleClickShowEyeIcon = () => setShowPassword((show) => !show);

  /* Handle Submit Function */
  const handleLogin = async (formValues) => {
    setIsLoginProceed(true);
    const formData = new FormData();
    formData.append("email", formValues?.email);
    formData.append("password", formValues?.password);
    dispatchLogin(formData);
  };

  /**Validation Schema */
  const loginSchema = yup.object().shape({
    email: yup
      .string("Must be string")
      .required("Email is required field.")
      .email("Please enter valid email format."),
    password: yup
      .string("Must be string")
      .required("Password is required field."),
  });

  /* Formik */
  const { handleSubmit, handleChange, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        email: rememberData?.email || "",
        password: rememberData?.password || "",
        remember: false,
      },
      validationSchema: loginSchema,
      onSubmit: handleLogin,
      enableReinitialize: true,
    });

  /** Login Success UseEffect */
  useEffect(() => {
    if (isLoginProceed) {
      if (loginDetails?.success === true) {
        if (rememberMe) {
          rememberMeInstance.set({
            email: values?.email,
            password: values?.password,
          });
        } else {
          rememberMeInstance.remove();
        }
        getAfterLoginUserDetailAction();
        dispatchResetState("loginDetails");
      }
      setIsLoginProceed(false);
    }
  }, [loginDetails?.success]);

  const renderLoginForm = () => {
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          autoComplete="off"
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            fullWidth
            name="email"
            value={values.email}
            type="text"
            placeholder="Email address *"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
            }}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <PrimaryIcon width="24px" color="white" icon={EmailIcon} />
                  </InputAdornment>
                </>
              ),
            }}
            error={touched.email && errors.email}
            helperText={touched.email && errors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            placeholder="Password *"
            type={showPassword ? "text" : "password"}
            id="password"
            value={values.password}
            autoComplete="current-password"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
            }}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <PrimaryIcon width="24px" color="white" icon={LockIcon} />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={handleClickShowEyeIcon}
                    className={classes.cSPassword}
                  >
                    {showPassword ? (
                      <PrimaryIcon width="24px" icon={eyeIcon} />
                    ) : (
                      <PrimaryIcon width="24px" icon={crossedEyeIcon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={touched.password && errors.password}
            helperText={touched.password && errors.password}
          />
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                value={rememberMe}
                control={
                  <Checkbox
                    checked={rememberMe}
                    value="remember"
                    color="primary"
                  />
                }
                label="Remember me"
                onChange={() => setRememberMe(!rememberMe)}
                className={classes.cSRemember}
                slotProps={{
                  typography: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              className={classes.cSForgot}
              onClick={() => navigate("/forgot_password")}
            >
              <Typography variant="h4" bold>
                Forgot Password?
              </Typography>
            </Grid>
          </Grid>
          <AuthButton
            children={"Sign In"}
            disabled={loginDetails?.isLoading}
            loading={loginDetails?.isLoading}
          />
        </form>
      </>
    );
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <CarouselAuth />
      <>
        <Grid item xs={12} sm={12} md={9} lg={6} className={classes.cSMainGrid}>
          <Box
            sx={{
              my: width < 1400 ? 10 : 20,
              mx: width < 1600 ? 5 : 30,
            }}
          >
            <Box className={classes.cSMainLogo}>
              <PrimaryIcon color="white" icon={mobileLogo} />
            </Box>
            <Box>
              <Typography variant="h3" bold className={classes.cSMainHeading}>
                Login to your account
              </Typography>
            </Box>
            {renderLoginForm()}
          </Box>
        </Grid>
      </>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.auth.loginDetails,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (formData) => dispatch({ type: START_LOGIN, formData }),
  dispatchResetState: (key, payload) =>
    dispatch({ type: RESET_STATE, key, payload }),
  getAfterLoginUserDetailAction: () =>
    dispatch({ type: GET_AFTER_LOGIN_USER_DETAIL }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
