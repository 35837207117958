//Get User Details
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const SUCCESS_USER_DETAIL = "SUCCESS_USER_DETAIL";
export const ERROR_USER_DETAIL = "ERROR_USER_DETAIL";

//Get User Details
export const GET_AFTER_LOGIN_USER_DETAIL = "GET_AFTER_LOGIN_USER_DETAIL";
export const SUCCESS_AFTER_LOGIN_USER_DETAIL =
  "SUCCESS_AFTER_LOGIN_USER_DETAIL";
export const ERROR_AFTER_LOGIN_USER_DETAIL = "ERROR_AFTER_LOGIN_USER_DETAIL";
