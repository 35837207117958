import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import API from "./Api";
import { ROLES } from "utils/data";
import { connect } from "react-redux";
import Login from "./Container/Auth/Login";
import SignUp from "./Container/Auth/SignUp";
import { LOGOUT_START } from "Action/AuthAction";
import PageLoading from "Components/Common/PageLoading";
import ForgotPassword from "./Container/Auth/ForgotPassword";
import NotificationManager from "./Hooks/NotificationManager";
import SetLoginPassword from "./Container/Auth/SetLoginPassword";
import { GET_AFTER_LOGIN_USER_DETAIL } from "./Action/UserAction";
import { isLoggedIn, protectedRoute } from "./utils/generalUtils";
import PatientPublicViewNotFound from "./Components/PatientView/PatientPublicViewNotFound";

const ResetPassword = React.lazy(() =>
  import("./Container/Auth/ResetPassword")
);
const DoctorContainer = React.lazy(() =>
  import("./Container/DoctorsContainer")
);
const DashboardContainer = React.lazy(() =>
  import("./Container/DashboardContainer")
);
const PharmaStoreContainer = React.lazy(() =>
  import("Container/PharmaStoreContainer")
);
const StaffMemberContainer = React.lazy(() =>
  import("./Container/StaffMemberContainer")
);
const PrescriptionManagementContainer = React.lazy(() =>
  import("./Container/PrescriptionManagementContainer")
);
const PatientManagementContainer = React.lazy(() =>
  import("./Container/PatientManagementContainer")
);
const PatientViewContainer = React.lazy(() =>
  import("./Container/PatientViewContainer")
);
const VerifyPrescriptionContainer = React.lazy(() =>
  import("./Container/VerifyPrescriptionContainer")
);

const Main = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { logout, getAfterLoginUserDetailAction, getAfterLoginUserDetails } =
    props;
  const token = API.getAccessTokenFromLocalStorage();
  const [changeOrderStatusBasedOnClick, seChangeOrderStatusBasedOnClick] =
    useState("");

  useEffect(() => {
    if (getAfterLoginUserDetails?.data?.data?.is_active === false) {
      logout();
    }
  }, [getAfterLoginUserDetails]);

  useEffect(() => {
    if (token && token !== "") {
      getAfterLoginUserDetailAction();
    } else if (
      [
        "forgot_password",
        "reset_password",
        "set_password",
        "patients",
        "sign_up",
        "verify_prescriptions",
      ].includes(path)
    ) {
    } else {
      if (["login"].includes(path)) {
        navigate("/login");
      } else {
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (token || token !== null) {
      if (getAfterLoginUserDetails?.data?.data?.role === "pharma_store") {
        navigate("/pharma_store");
      } else {
        if (Object.keys(getAfterLoginUserDetails?.data[2] || {})?.length) {
          navigate("/dashboard");
        }
        if (protectedRoute.includes(path)) {
          navigate("/dashboard");
        }
      }
    }
  }, [location.pathname]);

  const routes = [
    {
      element: <Login />,
      path: "/login",
      exact: true,
    },
    {
      element: <SetLoginPassword />,
      path: "/set_password/:token",
      exact: true,
    },
    {
      element: <SignUp />,
      path: "/sign_up",
      exact: true,
    },
    {
      element: <ForgotPassword />,
      path: "/forgot_password",
      exact: true,
    },
    {
      element: <ResetPassword />,
      path: "/reset_password/:token",
      exact: true,
    },
    {
      element: (
        <DashboardContainer
          seChangeOrderStatusBasedOnClick={seChangeOrderStatusBasedOnClick}
        />
      ),
      path: "/dashboard",
      exact: true,
      accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
    },
    {
      element: <DoctorContainer />,
      path: "/doctors",
      exact: true,
      accesible_roles: [ROLES.CLINIC_OWNER],
    },
    {
      element: <StaffMemberContainer />,
      path: "/staff_members",
      exact: true,
      accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR],
    },
    {
      element: <PatientManagementContainer />,
      path: "/patients",
      exact: true,
      accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
    },
    {
      element: (
        <PrescriptionManagementContainer
          changeOrderStatusBasedOnClick={changeOrderStatusBasedOnClick}
          seChangeOrderStatusBasedOnClick={seChangeOrderStatusBasedOnClick}
        />
      ),
      path: "/prescriptions",
      exact: true,
      accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
    },
    {
      element: <PatientViewContainer />,
      path: "/patients/:id",
      exact: true,
    },
    {
      element: <PharmaStoreContainer />,
      path: "/pharma_store",
      exact: true,
      accesible_roles: [ROLES.PHARMASTORE],
    },
    {
      element: <PatientPublicViewNotFound />,
      path: "/patients-public-view/invalid",
      exact: true,
    },
    {
      element: <VerifyPrescriptionContainer />,
      path: "/verify_prescriptions",
      exact: true,
    },
  ];

  return (
    <>
      <Suspense fallback={null}>
        <NotificationManager />
        {getAfterLoginUserDetails?.loading && <PageLoading height={"100%"} />}
        {!getAfterLoginUserDetails?.loading &&
        (isLoggedIn()
          ? Object.keys(getAfterLoginUserDetails?.data?.data || {})?.length
          : true) ? (
          <>
            <Routes>
              {routes.map((item, key) => {
                return (
                  <Route
                    key={key}
                    {...item}
                    element={
                      isLoggedIn() ? (
                        item?.accesible_roles?.includes(
                          getAfterLoginUserDetails?.data?.data?.role
                        ) ? (
                          <>{item?.element}</>
                        ) : (
                          <Navigate
                            to={
                              isLoggedIn() &&
                              getAfterLoginUserDetails?.data?.data?.role ===
                                "pharma_store"
                                ? "/pharma_store"
                                : isLoggedIn()
                                ? "/dashboard"
                                : "/login"
                            }
                          />
                        )
                      ) : (
                        item?.element
                      )
                    }
                  />
                );
              })}
            </Routes>
          </>
        ) : null}
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to={isLoggedIn() ? "/dashboard" : "/login"} />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginUserDetail: state.user.loginUserDetail,
  getAfterLoginUserDetails: state.user.getAfterLoginUserDetails,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: LOGOUT_START }),
  getAfterLoginUserDetailAction: () =>
    dispatch({ type: GET_AFTER_LOGIN_USER_DETAIL }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
