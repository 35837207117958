import CryptoJS from "crypto-js";
import API from "../Api";

/**
 * @return if user is logged in
 * @author Nikita Mahajan
 */

export function isLoggedIn() {
  return Boolean(API.getAccessTokenFromLocalStorage());
}

/**
 * @return Set protectedRoute
 * @author Nikita Mahajan
 */

export const protectedRoute = [
  "login",
  "set_password",
  "sign_up",
  "forgot_password",
  "reset_password",
];

// export const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneRegExp = /^[0-9]{9,14}$/;

export const toggleInArray = (arr, key) => {
  if (arr?.includes(key)) {
    arr = arr.filter((item) => item !== key);
  } else {
    arr.push(key);
  }
  return arr;
};

export const generateDynamicEndpoint = (url, variables) => {
  Object.keys(variables).map((i) => {
    url = url.replace(`{${i}}`, variables[i]);
  });

  return url;
};

export const getClearObject = (obj = {}) => {
  const newObj = {};
  Object.keys(obj).map((key) => {
    const item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });
  return newObj;
};

export const getNewOrder = (sequence, mainObject) => {
  if (sequence.length === 0) {
    return mainObject;
  }
  let finalObj = {};
  sequence.map((col) => {
    finalObj[col] = {
      ...mainObject[col],
    };
  });
  return finalObj;
};

export const capitalizeFirstLetter = (string) => {
  if (string === null || string === "" || string === undefined) return "";
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const ucwords = (str) => {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const isEmptyObject = (objectVal) => {
  return (
    Object.keys(objectVal).length === 0 && objectVal.constructor === Object
  ); // 👈 constructor check
};

export const abbreviateNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const setLocalStorage = (key, value) => {
  if (typeof Storage !== "undefined") {
    let storeValue = value;
    if (value?.constructor === Object) {
      storeValue = JSON.stringify(value);
    }
    localStorage.setItem(key, storeValue);
  } else {
    // No web storage Support.
    console.warn("Local storage not supporting");
  }
};

export const getLocalStorage = (key, isObject) => {
  // if (!localStorage.hasOwnProperty(key)) {
  //   //throw Error(`${key} not present in local storage`);
  //   console.warn(`${key} not present in local storage`);
  // }
  let localStorageValue = localStorage.getItem(key);
  return isObject ? JSON.parse(localStorageValue) : localStorageValue;
};

export const clearLocalStorage = () => {
  localStorage.removeItem("token");
};

export const logoutUser = () => {
  clearLocalStorage();
  window.location.href = "/login";
};

/**
 * Change browser url without page refreshing
 * @param url string full url
 * @author
 * @return (bool) true/false
 */
export const changeBrowserURlWithoutPageRefresh = (url) => {
  if (url !== undefined && url !== "" && window.history.pushState) {
    window.history.pushState({ path: url }, "", url);
    return true;
  }
  return false;
};

/**
 * Remove the given query string from url string and return it
 *
 * @param  key string parameter name
 * @param  sourceURL string full url
 * @author
 * @return return url string
 */

export const removeParamFromQueryString = (key, sourceURL) => {
  sourceURL = sourceURL || window.location.href;
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

/**
 * Get removed url params
 *
 * @return {object}
 * @author Nikita Mahajan
 */
export function getRemovedUrlParam(arrayOfKey) {
  let params = new URLSearchParams(window.location.search);
  for (let i = 0; i < arrayOfKey.length; i++) {
    params.delete(arrayOfKey[i]);
  }
  return Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
}

export function screenWidth() {
  return window.screen.availWidth;
}

/** text function to substring*/
export function getEllipsisName(value, itemlength) {
  return value?.length > itemlength
    ? `${value?.substr(0, itemlength - 3)}...`
    : value;
}

// Remember me functions

export class rememberMeFunc {
  set(data) {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_REMEMBER_ME_SECRET_KEY
    );
    localStorage.setItem("remember_me", encrypted);
  }
  remove() {
    localStorage.removeItem("remember_me");
  }
  get() {
    const token = localStorage.getItem("remember_me");
    if (token) {
      var bytes = CryptoJS.AES.decrypt(
        token,
        process.env.REACT_APP_REMEMBER_ME_SECRET_KEY
      );
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) || {};
    }
    return {};
  }
}
