import React, { useState, useEffect } from "react";
import {
  Grid,
  CssBaseline,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useViewport from "../../Hooks/useViewport";
import AuthButton from "../../Components/Common/AuthButton";
import PrimaryIcon from "../../Components/Common/PrimaryIcon";
import CarouselAuth from "../../Components/Common/CarouselAuth";
import { ReactComponent as EmailIcon } from "../../Assets/EmailIcon.svg";
import { ReactComponent as mobileLogo } from "../../Assets/mobileLogo.svg";
import { START_FORGOT_PASSWORD, RESET_STATE } from "../../Action/AuthAction";

const useStyle = makeStyles((theme) => ({
  cSMainHeading: {
    color: `${theme.palette.background.blackText} !important`,
    letterSpacing: "0.2px !important",
    marginBottom: "10px !important",
  },
  cSDisabledField: {
    "& .Mui-disabled": {
      backgroundColor: `${theme.palette.background.field} !important`,
    },
  },
  cSBackText: {
    marginTop: "25px !important",
    cursor: "pointer",
    "&>h4": {
      fontSize: "16px !important",
      color: `${theme.palette.primary.main} !important`,
    },
  },
  cSMainLogo: {
    display: "none",
    [theme.breakpoints.down("1300")]: {
      textAlign: "center",
      marginBottom: "50px",
      display: "block",
    },
  },
  cSMainGrid: {
    [theme.breakpoints.down("1300")]: {
      margin: "0 auto !important",
    },
  },
}));

function ForgotPassword({
  dispatchForgotPassword,
  forgotPasswordDetail,
  dispatchResetState,
}) {
  const classes = useStyle();
  const { width } = useViewport();
  const navigate = useNavigate();
  const [isForgotProceed, setIsForgotProceed] = useState(false);

  /* Handle Send Email */
  const handleSendEmail = (formValues) => {
    setIsForgotProceed(true);
    const formData = new FormData();
    formData.append("email", formValues?.email);
    dispatchForgotPassword(formData);
  };

  /* Forgot password validation schema */
  const forgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .required("The email field is required.")
      .email("Please enter valid email format."),
  });

  /* Formik */
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setFieldError,
    handleBlur,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSendEmail,
    enableReinitialize: true,
  });

  /* Forgot password Success useEffect */
  useEffect(() => {
    if (isForgotProceed) {
      if (!forgotPasswordDetail?.success) {
        if (forgotPasswordDetail?.errors?.hasOwnProperty("email")) {
          setFieldError("email", forgotPasswordDetail.errors.email[0]);
        }
      } else {
        resetForm();
        dispatchResetState("forgotPasswordDetail", "");
        navigate("/login");
      }
      setIsForgotProceed(false);
    }
  }, [forgotPasswordDetail?.success, forgotPasswordDetail?.errors]);

  const renderForgotPasswordForm = () => {
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          autoComplete="off"
          sx={{ mt: 1 }}
        >
          <Box className={classes.cSDisabledField}>
            <TextField
              margin="normal"
              fullWidth
              name="email"
              placeholder="Email address *"
              type="Email address"
              value={values.email}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <PrimaryIcon
                        width="20px"
                        color="white"
                        icon={EmailIcon}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
            />
          </Box>
          <AuthButton
            children={"Send"}
            disabled={forgotPasswordDetail?.isLoading}
            loading={forgotPasswordDetail?.isLoading}
          />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={classes.cSBackText}
            onClick={() => navigate("/login")}
          >
            <Typography variant="h4" bold>
              Back to Login
            </Typography>
          </Box>
        </form>
      </>
    );
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <CarouselAuth />
      <Grid item xs={12} sm={12} md={8} lg={6} className={classes.cSMainGrid}>
        <Box
          sx={{
            my: width < 1400 ? 10 : 20,
            mx: width < 1600 ? 5 : 30,
          }}
        >
          <Box className={classes.cSMainLogo}>
            <PrimaryIcon color="white" icon={mobileLogo} />
          </Box>
          <Box>
            <Typography variant="h3" bold className={classes.cSMainHeading}>
              Forgot password?
            </Typography>
          </Box>
          {renderForgotPasswordForm()}
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  forgotPasswordDetail: state.auth.forgotPasswordDetail,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchForgotPassword: (formData) =>
    dispatch({ type: START_FORGOT_PASSWORD, formData }),
  dispatchResetState: (key, payload) =>
    dispatch({ type: RESET_STATE, key, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
