//Get Pharma Store List
export const GET_PHARMA_STORE_LIST = "GET_PHARMA_STORE_LIST";
export const SUCCESS_PHARMA_STORE_LIST = "SUCCESS_PHARMA_STORE_LIST";
export const ERROR_PHARMA_STORE_LIST = "ERROR_PHARMA_STORE_LIST";

//Get Update Order Status
export const GET_UPDATE_ORDER_STATUS = "GET_UPDATE_ORDER_STATUS";
export const SUCCESS_UPDATE_ORDER_STATUS = "SUCCESS_UPDATE_ORDER_STATUS";
export const ERROR_UPDATE_ORDER_STATUS = "ERROR_UPDATE_ORDER_STATUS";

//Reset Pharma Store
export const RESET_PHARMA_STORE = "RESET_PHARMA_STORE";
