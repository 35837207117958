import {
  GET_PATIENT_MANAGEMENT_LIST,
  SUCCESS_PATIENT_MANAGEMENT_LIST,
  ERROR_PATIENT_MANAGEMENT_LIST,
  RESET_PATIENT_MANAGEMENT,
  START_CREATE_PATIENT_MANAGEMENT,
  SUCCESS_CREATE_PATIENT_MANAGEMENT,
  ERROR_CREATE_PATIENT_MANAGEMENT,
  START_DELETE_PATIENT_MANAGEMENT,
  SUCCESS_DELETE_PATIENT_MANAGEMENT,
  ERROR_DELETE_PATIENT_MANAGEMENT,
  START_UPDATE_PATIENT_MANAGEMENT,
  SUCCESS_UPDATE_PATIENT_MANAGEMENT,
  ERROR_UPDATE_PATIENT_MANAGEMENT,
  GET_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
  SUCCESS_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
  ERROR_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID,
} from "../Action/PatientManagementAction";

const initialState = {
  getPatientManagementList: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
  createPatientManagementData: {
    loading: false,
    data: [],
    error: {},
  },
  deletePatientManagementData: {
    loading: false,
    data: {},
    error: {},
  },
  updatePatientManagementData: {
    loading: false,
    data: {},
    error: {},
  },
  singlePatientManagementData: {
    loading: false,
    data: {},
    error: {},
  },
};

const PatientManagementReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PATIENT_MANAGEMENT: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action.key],
      };
    }
    case GET_PATIENT_MANAGEMENT_LIST: {
      let oldList = state?.getPatientManagementList?.data;
      return {
        ...state,
        getPatientManagementList: {
          loading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_PATIENT_MANAGEMENT_LIST: {
      let oldList = state?.getPatientManagementList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        getPatientManagementList: {
          loading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_PATIENT_MANAGEMENT_LIST: {
      return {
        ...state,
        getPatientManagementList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_PATIENT_MANAGEMENT: {
      return {
        ...state,
        createPatientManagementData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_CREATE_PATIENT_MANAGEMENT: {
      let oldList = state?.getPatientManagementList?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [action?.payload?.data, ...oldList?.list];
        oldList.filtered_record = oldList?.filtered_record + 1;
      }
      return {
        ...state,
        createPatientManagementData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_CREATE_PATIENT_MANAGEMENT: {
      return {
        ...state,
        createPatientManagementData: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case START_DELETE_PATIENT_MANAGEMENT: {
      return {
        ...state,
        deletePatientManagementData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_DELETE_PATIENT_MANAGEMENT: {
      let oldList = state?.getPatientManagementList?.data;
      oldList.list = oldList?.list?.filter((item) => item?.id !== action?.id);
      oldList.filtered_record = oldList?.filtered_record - 1;
      return {
        ...state,
        deletePatientManagementData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_DELETE_PATIENT_MANAGEMENT: {
      return {
        ...state,
        deletePatientManagementData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_PATIENT_MANAGEMENT: {
      return {
        ...state,
        updatePatientManagementData: {
          loading: true,
          data: {},
          error: {},
        },
      };
    }
    case SUCCESS_UPDATE_PATIENT_MANAGEMENT: {
      let oldList = state?.getPatientManagementList?.data;
      oldList.list = oldList?.list?.map((item) => {
        if (item?.id === action?.id) {
          return {
            ...item,
            ...action?.payload?.data,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        updatePatientManagementData: {
          loading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_PATIENT_MANAGEMENT: {
      return {
        ...state,
        updatePatientManagementData: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID: {
      let oldList = state?.singlePatientManagementData?.data;
      return {
        ...state,
        singlePatientManagementData: {
          loading: true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: {},
        },
      };
    }
    case SUCCESS_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID: {
      let oldList = state?.singlePatientManagementData?.data;
      if (oldList?.list?.length > 0) {
        oldList.list = [...oldList?.list, ...action?.payload?.data?.list];
      } else {
        oldList = action?.payload?.data;
      }
      return {
        ...state,
        singlePatientManagementData: {
          loading: false,
          data: oldList,
          error: {},
        },
      };
    }
    case ERROR_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID: {
      return {
        ...state,
        singlePatientManagementData: {
          loading: false,
          data: {},
          error: {},
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default PatientManagementReducers;
