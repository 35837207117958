import {
  RESET_CONFIG_STATE,
  ADD_NEW_NOTIFICATION,
  CLOSE_NOTIFICATION,
} from "../Action/ConfigAction";

const initialState = {
  notificationDetail: {
    notifications: [],
  },
};

const notificationConfig = {
  duration: 4000,
  type: "success",
  open: true,
  url: "",
};

export const configReducer = (state = initialState, action) => {
  switch (action?.type) {
    case RESET_CONFIG_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case ADD_NEW_NOTIFICATION: {
      const newNotification = {
        ...notificationConfig,
        ...action.payload,
        id: Math.round(new Date().valueOf()),
      };

      const data = [
        ...state?.notificationDetail?.notifications,
        newNotification,
      ];

      return {
        ...state,
        notificationDetail: {
          notifications: data,
        },
      };
    }

    case CLOSE_NOTIFICATION: {
      let newNotification = state?.notificationDetail?.notifications?.map(
        (item) => {
          if (item?.id === action?.id) {
            return {
              ...item,
              open: false,
            };
          } else {
            return item;
          }
        }
      );

      return {
        ...state,
        notificationDetail: {
          notifications: newNotification,
        },
      };
    }

    default: {
      return state || initialState;
    }
  }
};
