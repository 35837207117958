import { all } from "redux-saga/effects";
import { AuthWatcher } from "./AuthSaga";
import { UserWatcher } from "./UserSaga";
import { DoctorsWatcher } from "./DoctorsSaga";
import { DashboardWatcher } from "./DashboardSaga";
import { StaffMemberWatcher } from "./StaffMemberSaga";
import { PatientManagementWatcher } from "./PatientManagementSaga";
import { PrescriptionWatcher } from "./PrescriptionSaga";
import { PatientViewWatcher } from "./PatientViewSaga";
import { PharmaStoreWatcher } from "./PharmaStoreSaga";
import { VerifyPickupCodeWatcher } from "./VerifyPrescriptionSaga";

export default function* rootSaga() {
  yield all([
    AuthWatcher(),
    UserWatcher(),
    DoctorsWatcher(),
    DashboardWatcher(),
    StaffMemberWatcher(),
    PatientManagementWatcher(),
    PrescriptionWatcher(),
    PatientViewWatcher(),
    PharmaStoreWatcher(),
    VerifyPickupCodeWatcher(),
  ]);
}
