import API from "../Api";
import {
  GET_STAFF_MEMBER_LIST_ENDPOINT,
  CREATE_STAFF_MEMBER_ENDPOINT,
  UPDATE_STAFF_MEMBER_ENDPOINT,
  SINGLE_STAFF_MEMBER_LIST_GET_BY_ID_ENDPOINT,
  DELETE_STAFF_MEMBER_ENDPOINT,
  DELETE_REPORTING_PERSON_LIST_ENDPOINT,
} from "./endpoints";
import { generateDynamicEndpoint } from "../utils/generalUtils";

export const getStaffMemberListAPI = (payload) => {
  return API.post(GET_STAFF_MEMBER_LIST_ENDPOINT, payload);
};

//Create Staff Member
export function createStaffMemberAPI(formData) {
  return API.post(CREATE_STAFF_MEMBER_ENDPOINT, formData);
}

//Update Staff Member
export const updateStaffMemberAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(UPDATE_STAFF_MEMBER_ENDPOINT, { id: id }),
    payload
  );
};

//Get Single Staff Member LIst By Id
export const getSingleListStaffMemberByIdAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(SINGLE_STAFF_MEMBER_LIST_GET_BY_ID_ENDPOINT, {
      id: id,
    }),
    payload
  );
};

//Delete Staff Member
export const deleteStaffMemberAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_STAFF_MEMBER_ENDPOINT, { id: id })
  );
};

//Reporting Person List
export const getReportingPersonListListAPI = (payload) => {
  return API.post(DELETE_REPORTING_PERSON_LIST_ENDPOINT, payload);
};
