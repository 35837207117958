import API from "../Api";
import {
  GET_PATIENT_VIEW_DETAILS_ENDPOINT,
  CREATE_DELIVER_ADDRESS_ENDPOINT,
  DELETE_DELIVER_ADDRESS_ENDPOINT,
  UPDATE_DELIVER_ADDRESS_ENDPOINT,
  GET_PATIENT_ADDRESS_DETAILS_ENDPOINT,
  SINGLE_DELIVER_ADDRESS_GET_BY_ID_ENDPOINT,
  PAYMENT_METHOD,
  GET_PICKUP_CODE,
  SET_PICKUP_OPTION,
} from "./endpoints";
import { generateDynamicEndpoint } from "../utils/generalUtils";

//Patient Details
export const getPatientViewAPI = (id) => {
  return API.get(
    generateDynamicEndpoint(GET_PATIENT_VIEW_DETAILS_ENDPOINT, { id: id })
  );
};

//Create Delivery Address With Payment
export const createDeliveryAddressWithPaymentAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(CREATE_DELIVER_ADDRESS_ENDPOINT, { id: id }),
    payload
  );
};

//Delete Delivery Address
export const deleteDeliveryAddressAPI = (id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_DELIVER_ADDRESS_ENDPOINT, { id: id })
  );
};

//Update Delivery Address
export const updateDeliveryAddressAPI = (id, payload) => {
  return API.post(
    generateDynamicEndpoint(UPDATE_DELIVER_ADDRESS_ENDPOINT, { id: id }),
    payload
  );
};

//Get Single Delivery Address By Id
export const getSingleDeliveryAddressByIdAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(SINGLE_DELIVER_ADDRESS_GET_BY_ID_ENDPOINT, {
      id: id,
    }),
    payload
  );
};

//Patient address Details
export const getPatientAddressAPI = (id) => {
  return API.get(
    generateDynamicEndpoint(GET_PATIENT_ADDRESS_DETAILS_ENDPOINT, { id: id })
  );
};

//Payment method
export const PaymentMethodAPI = (id, payload) => {
  return API.post(generateDynamicEndpoint(PAYMENT_METHOD, { id: id }), payload);
};

//Get Pickup Code
export const getPickupCodeAPI = (id) => {
  return API.get(generateDynamicEndpoint(GET_PICKUP_CODE, { id: id }));
};

//Set Pickup Option
export const setPickupOptionAPI = (id) => {
  return API.get(generateDynamicEndpoint(SET_PICKUP_OPTION, { id: id }));
};
