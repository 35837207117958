import { CreateBase } from "easy-redux-http-call";
import { store } from "../store";
import API from "../Api";

export const apiBase = new CreateBase({
  baseApiUrl: process.env.REACT_APP_API_BASE_URL,
  store: store,
  defaultState: {
    isLoading: false,
  },
  validateHttpResponse: (res) => res.success,
  httpFn: (data) => {
    return API?.[data?.method](data.endpoint, data?.params);
  },
});
