//Get Patient Management List
export const GET_PATIENT_MANAGEMENT_LIST = "GET_PATIENT_MANAGEMENT_LIST";
export const SUCCESS_PATIENT_MANAGEMENT_LIST =
  "SUCCESS_PATIENT_MANAGEMENT_LIST";
export const ERROR_PATIENT_MANAGEMENT_LIST = "ERROR_PATIENT_MANAGEMENT_LIST";

//Reset Patient Management Data State
export const RESET_PATIENT_MANAGEMENT = "RESET_PATIENT_MANAGEMENT";

//Create Patient Management
export const START_CREATE_PATIENT_MANAGEMENT =
  "START_CREATE_PATIENT_MANAGEMENT";
export const SUCCESS_CREATE_PATIENT_MANAGEMENT =
  "SUCCESS_CREATE_PATIENT_MANAGEMENT";
export const ERROR_CREATE_PATIENT_MANAGEMENT =
  "ERROR_CREATE_PATIENT_MANAGEMENT";

//Delete Patient Management
export const START_DELETE_PATIENT_MANAGEMENT =
  "START_DELETE_PATIENT_MANAGEMENT";
export const SUCCESS_DELETE_PATIENT_MANAGEMENT =
  "SUCCESS_DELETE_PATIENT_MANAGEMENT";
export const ERROR_DELETE_PATIENT_MANAGEMENT =
  "ERROR_DELETE_PATIENT_MANAGEMENT";

//Update Patient Management
export const START_UPDATE_PATIENT_MANAGEMENT =
  "START_UPDATE_PATIENT_MANAGEMENT";
export const SUCCESS_UPDATE_PATIENT_MANAGEMENT =
  "SUCCESS_UPDATE_PATIENT_MANAGEMENT";
export const ERROR_UPDATE_PATIENT_MANAGEMENT =
  "ERROR_UPDATE_PATIENT_MANAGEMENT";

//Single Patient Management Data Get
export const GET_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID =
  "GET_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID";
export const SUCCESS_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID =
  "SUCCESS_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID";
export const ERROR_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID =
  "ERROR_SINGLE_PATIENT_MANAGEMENT_LIST_BY_ID";
