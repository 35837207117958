import API from "../Api";
import {
  GET_PHARMA_STORE_LIST_ENDPOINT,
  GET_UPDATE_ORDER_STATUS_ENDPOINT,
} from "./endpoints";

//Get Pharma Store List
export const getPharmaStoreListAPI = (payload) => {
  return API.post(GET_PHARMA_STORE_LIST_ENDPOINT, payload);
};

//Get Update Order Status
export const getUpdateOrderStatusAPI = (payload) => {
  return API.post(GET_UPDATE_ORDER_STATUS_ENDPOINT, payload);
};
