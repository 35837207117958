import {
  START_DASHBOARD_LIST,
  SUCCESS_DASHBOARD_LIST,
  ERROR_DASHBOARD_LIST,
} from "../Action/DashBoardAction";

const initialState = {
  getDashboardList: {
    success: null,
    isLoading: false,
    data: {},
    message: "",
    errors: {},
  },
};

const DashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case START_DASHBOARD_LIST: {
      return {
        ...state,
        getDashboardList: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DASHBOARD_LIST: {
      return {
        ...state,
        getDashboardList: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DASHBOARD_LIST: {
      return {
        ...state,
        getDashboardList: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return state || initialState;
    }
  }
};

export default DashboardReducers;
