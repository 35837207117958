//Get Prescription List
export const GET_PRESCRIPTION_LIST = "GET_PRESCRIPTION_LIST";
export const SUCCESS_PRESCRIPTION_LIST = "SUCCESS_PRESCRIPTION_LIST";
export const ERROR_PRESCRIPTION_LIST = "ERROR_PRESCRIPTION_LIST";

//Reset Prescription Management Data State
export const RESET_PRESCRIPTION_MANAGEMENT = "RESET_PRESCRIPTION_MANAGEMENT";

//Create Prescription Management
export const START_CREATE_PRESCRIPTION_MANAGEMENT =
  "START_CREATE_PRESCRIPTION_MANAGEMENT";
export const SUCCESS_CREATE_PRESCRIPTION_MANAGEMENT =
  "SUCCESS_CREATE_PRESCRIPTION_MANAGEMENT";
export const ERROR_CREATE_PRESCRIPTION_MANAGEMENT =
  "ERROR_CREATE_PRESCRIPTION_MANAGEMENT";

//Delete Prescription Management
export const START_DELETE_PRESCRIPTION_MANAGEMENT =
  "START_DELETE_PRESCRIPTION_MANAGEMENT";
export const SUCCESS_DELETE_PRESCRIPTION_MANAGEMENT =
  "SUCCESS_DELETE_PRESCRIPTION_MANAGEMENT";
export const ERROR_DELETE_PRESCRIPTION_MANAGEMENT =
  "ERROR_DELETE_PRESCRIPTION_MANAGEMENT";

//Update Prescription Management
export const START_UPDATE_PRESCRIPTION_MANAGEMENT =
  "START_UPDATE_PRESCRIPTION_MANAGEMENT";
export const SUCCESS_UPDATE_PRESCRIPTION_MANAGEMENT =
  "SUCCESS_UPDATE_PRESCRIPTION_MANAGEMENT";
export const ERROR_UPDATE_PRESCRIPTION_MANAGEMENT =
  "ERROR_UPDATE_PRESCRIPTION_MANAGEMENT";

//Single Prescription Management Data Get
export const GET_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID =
  "GET_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID";
export const SUCCESS_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID =
  "SUCCESS_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID";
export const ERROR_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID =
  "ERROR_SINGLE_PRESCRIPTION_MANAGEMENT_LIST_BY_ID";

//Get Prescription How Much List
export const GET_PRESCRIPTION_HOW_MUCH_LIST = "GET_PRESCRIPTION_HOW_MUCH_LIST";
export const SUCCESS_PRESCRIPTION_HOW_MUCH_LIST =
  "SUCCESS_PRESCRIPTION_HOW_MUCH_LIST";
export const ERROR_PRESCRIPTION_HOW_MUCH_LIST =
  "ERROR_PRESCRIPTION_HOW_MUCH_LIST";

//Get Patient Name List
export const GET_PATIENT_NAME_LIST = "GET_PATIENT_NAME_LIST";
export const SUCCESS_PATIENT_NAME_LIST = "SUCCESS_PATIENT_NAME_LIST";
export const ERROR_PATIENT_NAME_LIST = "ERROR_PATIENT_NAME_LIST";

//Drug List
export const GET_DRUG_LIST = "GET_DRUG_LIST";
export const SUCCESS_DRUG_LIST = "SUCCESS_DRUG_LIST";
export const ERROR_DRUG_LIST = "ERROR_DRUG_LIST";

//Get bold Sign Link
export const GET_SIGN_LINK = "GET_SIGN_LINK";
export const SUCCESS_SIGN_LINK = "SUCCESS_SIGN_LINK";
export const ERROR_SIGN_LINK = "ERROR_SIGN_LINK";

//Get download bold Sign pdf
export const GET_DOWNLOAD_ZOHO_SIGN_LINK = "GET_DOWNLOAD_ZOHO_SIGN_LINK";
export const SUCCESS_DOWNLOAD_ZOHO_SIGN_LINK =
  "SUCCESS_DOWNLOAD_ZOHO_SIGN_LINK";
export const ERROR_DOWNLOAD_ZOHO_SIGN_LINK = "ERROR_DOWNLOAD_ZOHO_SIGN_LINK";

//Create Zoho Signature
export const START_CREATE_ZOHO_SIGNATURE = "START_CREATE_ZOHO_SIGNATURE";
export const SUCCESS_CREATE_ZOHO_SIGNATURE = "SUCCESS_CREATE_ZOHO_SIGNATURE";
export const ERROR_CREATE_ZOHO_SIGNATURE = "ERROR_CREATE_ZOHO_SIGNATURE";
