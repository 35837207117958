import API from "../Api";
import { apiBase } from "./apiBase";
import {
  SET_LOGIN_PASSWORD_ENDPOINT,
  SET_LOGIN_ENDPOINT,
  LOGOUT_USER_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SET_LOGIN_PASSWORD_VERIFY_LINK_ENDPOINT,
  SET_SIGN_UP_ENDPOINT,
} from "./endpoints";

//Set Login Password
export const setLoginPasswordAPI = async (formData, token) => {
  return await API.post(SET_LOGIN_PASSWORD_ENDPOINT, formData, token);
};

//Set Login Password
export const loginAPI = async (formData) => {
  return await API.post(SET_LOGIN_ENDPOINT, formData);
};

//Logout
export const logoutUserAPI = async () => {
  return await API.get(LOGOUT_USER_ENDPOINT);
};

//Forgot Password
export const forgotPasswordAPI = async (formData) => {
  return await API.post(FORGOT_PASSWORD_ENDPOINT, formData);
};

//Reset Password
export const resetPasswordAPI = async (formData) => {
  return await API.post(RESET_PASSWORD_ENDPOINT, formData);
};

//Set password verify link
export const getSetLoginVerifyLinkAPI = (id) => {
  return API.get(`${SET_LOGIN_PASSWORD_VERIFY_LINK_ENDPOINT}?id=${id}`);
};

//sign up
export const signUpAPI = async (formData) => {
  return await API.post(SET_SIGN_UP_ENDPOINT, formData);
};

export const AI_LOGIN = apiBase.createInstance({
  endpoint: SET_LOGIN_ENDPOINT,
  method: "post",
  reducer: "auth",
  reduxKey: "loginDetails",
  action: "LOGIN",
  onSuccess: (data) => data,
});
