import React, { useEffect, useState } from "react";
import {
  Grid,
  CssBaseline,
  TextField,
  Typography,
  InputAdornment,
  Box,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import {
  RESET_STATE,
  START_SET_LOGIN_PASSWORD,
  START_SET_LOGIN_VERIFY_LINK,
} from "../../Action/AuthAction";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import useViewport from "../../Hooks/useViewport";
import { GET_USER_DETAIL } from "../../Action/UserAction";
import { useNavigate, useParams } from "react-router-dom";
import AuthButton from "../../Components/Common/AuthButton";
import PrimaryIcon from "../../Components/Common/PrimaryIcon";
import CarouselAuth from "../../Components/Common/CarouselAuth";
import { useNotification } from "../../Hooks/NotificationManager";
import { ReactComponent as eyeIcon } from "../../Assets/eyeIcon.svg";
import { GET_AFTER_LOGIN_USER_DETAIL } from "../../Action/UserAction";
import { ReactComponent as LockIcon } from "../../Assets/LockIcon.svg";
import { ReactComponent as EmailIcon } from "../../Assets/EmailIcon.svg";
import { ReactComponent as mobileLogo } from "../../Assets/mobileLogo.svg";
import { ReactComponent as crossedEyeIcon } from "../../Assets/crossedEyeIcon.svg";

const useStyle = makeStyles((theme) => ({
  cSPassword: {
    left: "15px !important",
    "&>svg": {
      height: "24px",
      width: "24px",
      fill: `${theme.palette.background.iconColor} !important`,
    },
  },
  cSMainHeading: {
    color: `${theme.palette.background.blackText} !important`,
    letterSpacing: "0.2px !important",
    marginBottom: "10px !important",
  },
  cSDisabledField: {
    "& .Mui-disabled": {
      backgroundColor: `${theme.palette.background.field} !important`,
      border: "1px solid  #E2E8F0",
      "&>input": {
        border: "none",
        " -webkit-text-fill-color": `${theme.palette.background.black} !important`,
      },
    },
  },
  cSMainLogo: {
    display: "none",
    [theme.breakpoints.down("1300")]: {
      textAlign: "center",
      marginBottom: "50px",
      display: "block",
    },
  },
  cSMainGrid: {
    [theme.breakpoints.down("1300")]: {
      margin: "0 auto !important",
    },
  },
}));

function SetLoginPassword({
  dispatchSetLoginPassword,
  setLoginPasswordDetails,
  getSetLoginPasswordUserAction,
  setLoginPasswordUserDetails,
  getSetLoginPasswordVerifyLinkAction,
  setLoginVerifyLink,
  getAfterLoginUserDetailAction,
  dispatchResetState,
}) {
  const classes = useStyle();
  const params = useParams();
  const notification = useNotification();
  const { width } = useViewport();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPasswordEyeIcon = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPasswordEyeIcon = () =>
    setShowConfirmPassword((show) => !show);

  /* Handle Submit Function */
  const handleSetLoginPassword = async (formValues) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", formValues?.password);
    formData.append("password_confirmation", formValues?.password_confirmation);
    dispatchSetLoginPassword(formData, params?.token);
  };

  /**Validation Schema */
  const setLoginPasswordSchema = yup.object().shape({
    password: yup
      .string("Must be string")
      .required("Password is required field."),
    password_confirmation: yup
      .string("Must be string")
      .required("Confirm Password is required field.")
      .oneOf(
        [yup.ref("password"), null],
        "Confirm password should be same as password"
      ),
  });

  /* Formik */
  const { handleSubmit, handleChange, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      validationSchema: setLoginPasswordSchema,
      onSubmit: handleSetLoginPassword,
      enableReinitialize: true,
    });

  /* Send Link UseEffect for Set Login Password And User Detail API */
  useEffect(() => {
    getSetLoginPasswordVerifyLinkAction(params?.token);
    getSetLoginPasswordUserAction(params.token);
  }, []);

  /* Set Login Password Verify Success UseEffect */
  useEffect(() => {
    if (setLoginVerifyLink?.error?.success === false) {
      notification.error("The given link is invalid or link has been expired");
      navigate("/login");
    }
  }, [setLoginVerifyLink?.error?.success]);

  /* Set Login Password Page Open Based On Set Data UseEffect */
  useEffect(() => {
    setEmail(setLoginPasswordUserDetails?.data?.data?.email);
  }, [setLoginPasswordUserDetails?.data?.data?.email]);

  /* Login Success UseEffect */
  useEffect(() => {
    if (setLoginPasswordDetails?.success === true) {
      getAfterLoginUserDetailAction();
      dispatchResetState("setLoginPasswordDetails");
    }
  }, [setLoginPasswordDetails?.success]);

  const renderSetLoginPasswordForm = () => {
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          autoComplete="off"
          sx={{ mt: 1 }}
        >
          <Box className={classes.cSDisabledField}>
            <TextField
              margin="normal"
              fullWidth
              disabled
              value={email}
              name="email"
              placeholder="Email address *"
              type="Email address"
              sx={{
                "& fieldset": { border: "none" },
              }}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <PrimaryIcon
                        width="24px"
                        color="white"
                        icon={EmailIcon}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          <TextField
            margin="normal"
            fullWidth
            name="password"
            placeholder="Password *"
            type={showPassword ? "text" : "password"}
            id="password"
            value={values.password}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
            error={touched.password && errors.password}
            helperText={touched.password && errors.password}
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <PrimaryIcon width="24px" color="white" icon={LockIcon} />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={handleClickShowPasswordEyeIcon}
                    className={classes.cSPassword}
                  >
                    {showPassword ? (
                      <PrimaryIcon width="24px" icon={eyeIcon} />
                    ) : (
                      <PrimaryIcon width="24px" icon={crossedEyeIcon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password_confirmation"
            placeholder="Confirm password *"
            type={showConfirmPassword ? "text" : "password"}
            id="password_confirmation"
            onChange={(e) => {
              handleChange(e);
            }}
            value={values.password_confirmation}
            onBlur={handleBlur}
            error={
              touched.password_confirmation && errors.password_confirmation
            }
            helperText={
              touched.password_confirmation && errors.password_confirmation
            }
            autoComplete="password_confirmation"
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <PrimaryIcon width="24px" color="white" icon={LockIcon} />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={handleClickShowConfirmPasswordEyeIcon}
                    className={classes.cSPassword}
                  >
                    {showConfirmPassword ? (
                      <PrimaryIcon width="24px" icon={eyeIcon} />
                    ) : (
                      <PrimaryIcon width="24px" icon={crossedEyeIcon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <AuthButton
            children={"Set Password"}
            disabled={setLoginPasswordDetails?.isLoading}
            loading={setLoginPasswordDetails?.isLoading}
          />
        </form>
      </>
    );
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <CarouselAuth />
      <Grid item xs={12} sm={8} md={6} lg={6} className={classes.cSMainGrid}>
        <Box
          sx={{
            my: width < 1400 ? 10 : 20,
            mx: width < 1600 ? 5 : 30,
          }}
        >
          <Box className={classes.cSMainLogo}>
            <PrimaryIcon color="white" icon={mobileLogo} />
          </Box>
          <Box>
            <Typography variant="h3" bold className={classes.cSMainHeading}>
              Set login password
            </Typography>
          </Box>
          {renderSetLoginPasswordForm()}
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  setLoginPasswordDetails: state.auth.setLoginPasswordDetails,
  setLoginPasswordUserDetails: state.user.setLoginPasswordUserDetails,
  setLoginVerifyLink: state.auth.setLoginVerifyLink,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchSetLoginPassword: (formData, token) =>
    dispatch({ type: START_SET_LOGIN_PASSWORD, formData, token }),
  getSetLoginPasswordUserAction: (id) =>
    dispatch({ type: GET_USER_DETAIL, id }),
  getSetLoginPasswordVerifyLinkAction: (id) =>
    dispatch({ type: START_SET_LOGIN_VERIFY_LINK, id }),
  getAfterLoginUserDetailAction: () =>
    dispatch({ type: GET_AFTER_LOGIN_USER_DETAIL }),
  dispatchResetState: (key, payload) =>
    dispatch({ type: RESET_STATE, key, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetLoginPassword);
