// Auth Api End Point
export const SET_LOGIN_PASSWORD_ENDPOINT = "/clinic/set-login-passwprd";
export const SET_LOGIN_ENDPOINT = "/clinic/login";
export const LOGOUT_USER_ENDPOINT = "/clinic/logout";
export const FORGOT_PASSWORD_ENDPOINT = "/clinic/forgot-password";
export const RESET_PASSWORD_ENDPOINT = "/clinic/reset-password";
export const SET_LOGIN_PASSWORD_VERIFY_LINK_ENDPOINT =
  "/clinic/set-login-exist";
export const SET_SIGN_UP_ENDPOINT = "/clinic/register";

//Get user Details
export const GET_USER_DETAIL_ENDPOINT = "/clinic/user-details";

//After Login User Details
export const GET_AFTER_LOGIN_USER_DETAIL_ENDPOINT = "/user-details";

//Doctors
export const GET_DOCTORS_LIST_ENDPOINT = "/doctor/list";
export const CREATE_DOCTORS_ENDPOINT = "/doctor/store";
export const UPDATE_DOCTORS_ENDPOINT = "/doctor/update/{id}";
export const SINGLE_DOCTORS_LIST_GET_BY_ID_ENDPOINT = "/doctor/edit/{id}";
export const DELETE_DOCTOR_ENDPOINT = "/doctor/delete/{id}";
export const DELETE_REPORTING_PERSON_LIST_ENDPOINT = "/doctor-list";
export const ASSIGN_OTHER_DOCTOR_ENDPOINT = "/doctor/assign";
export const STAFF_AND_PATIENT_COUNT_ENDPOINT = "/doctor/staff-patient/{id}";

//Dashboard
export const GET_DASHBOARD_LIST_ENDPOINT = "/doctor/staff";

//Staff Member
export const GET_STAFF_MEMBER_LIST_ENDPOINT = "/staff_member/list";
export const CREATE_STAFF_MEMBER_ENDPOINT = "/staff_member/store";
export const UPDATE_STAFF_MEMBER_ENDPOINT = "/staff_member/update/{id}";
export const SINGLE_STAFF_MEMBER_LIST_GET_BY_ID_ENDPOINT =
  "/staff_member/edit/{id}";
export const DELETE_STAFF_MEMBER_ENDPOINT = "/staff_member/delete/{id}";

//Patient Management
export const GET_PATIENT_MANAGEMENT_LIST_ENDPOINT = "/patient/list";
export const CREATE_PATIENT_MANAGEMENT_ENDPOINT = "/patient/store";
export const DELETE_PATIENT_MANAGEMENT_ENDPOINT = "/patient/delete/{id}";
export const UPDATE_PATIENT_MANAGEMENT_ENDPOINT = "/patient/update/{id}";
export const SINGLE_PATIENT_MANAGEMENT_LIST_GET_BY_ID_ENDPOINT =
  "/patient/edit/{id}";

//Prescription Management
export const GET_PRESCRIPTION_LIST_ENDPOINT = "/prescription/list";
export const CREATE_PRESCRIPTION_ENDPOINT = "/prescription/store";
export const DELETE_PRESCRIPTION_ENDPOINT = "/prescription/delete/{id}";
export const UPDATE_PRESCRIPTION_ENDPOINT = "/prescription/update/{id}";
export const SINGLE_PRESCRIPTION_LIST_GET_BY_ID_ENDPOINT =
  "/prescription/edit/{id}";
export const GET_PRESCRIPTION_HOW_MUCH_LIST_ENDPOINT = "/how/list";
export const GET_PATIENT_NAME_LIST_ENDPOINT = "/prescription-patient/list";
export const GET_DRUG_LIST_ENDPOINT = "/drugs/list";
export const GET_SIGN_LINK_ENDPOINT = "/prescription/get-signLink/{id}";
export const GET_DOWNLOAD_ZOHO_SIGN_PDF_ENDPOINT =
  "/prescription/zoho-signature/download-doc";

//Patient View
export const GET_PATIENT_VIEW_DETAILS_ENDPOINT = "/prescription/details/{id}";
export const CREATE_DELIVER_ADDRESS_ENDPOINT = "/patient-delivery/store/{id}";
export const DELETE_DELIVER_ADDRESS_ENDPOINT = "/patient-delivery/delete/{id}";
export const UPDATE_DELIVER_ADDRESS_ENDPOINT = "/patient-delivery/update/{id}";
export const SINGLE_DELIVER_ADDRESS_GET_BY_ID_ENDPOINT =
  "/patient-delivery/edit/{id}";
export const GET_PATIENT_ADDRESS_DETAILS_ENDPOINT =
  "/patient-delivery/list/{id}";
export const PAYMENT_METHOD = "/medicines/payment/{id}";
export const GET_PICKUP_CODE = "/prescription/get-pickup-code/{id}";
export const SET_PICKUP_OPTION = "/prescription/set-pickup-option/{id}";

//Pharma Store
export const GET_PHARMA_STORE_LIST_ENDPOINT = "/pharma-store/orders";
export const GET_UPDATE_ORDER_STATUS_ENDPOINT =
  "/pharma-store/update-order-status";

//Verify Pickup Code
export const VERIFY_PICKUP_CODE_ENDPOINT = "/prescription/verify-pickup-code";
export const CREATE_PHARMACY_DETAILS_ENDPOINT =
  "/prescription/set-pharmacy-details";

//Zoho
export const GET_SIGNATURE_ENDPOINT =
  "/prescription/zoho-signature/get-sign-link";
export const CREATE_SIGNATURE_ENDPOINT = "/prescription/zoho-signature/create";
