import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_PHARMA_STORE_LIST,
  SUCCESS_PHARMA_STORE_LIST,
  ERROR_PHARMA_STORE_LIST,
  GET_UPDATE_ORDER_STATUS,
  SUCCESS_UPDATE_ORDER_STATUS,
  ERROR_UPDATE_ORDER_STATUS,
} from "../Action/PharmaStoreAction";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";
import {
  getPharmaStoreListAPI,
  getUpdateOrderStatusAPI,
} from "../Api/PharmaStoreApis";

function* getPharmaStoreListWorker({ payload }) {
  try {
    const res = yield call(getPharmaStoreListAPI, payload);
    if (res?.success) {
      yield put({
        type: SUCCESS_PHARMA_STORE_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_PHARMA_STORE_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateOrderStatusWorker({ payload }) {
  try {
    const res = yield call(getUpdateOrderStatusAPI, payload);
    if (res.success) {
      yield put({
        type: SUCCESS_UPDATE_ORDER_STATUS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Status update successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_ORDER_STATUS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.data.errors[0] || res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

export function* PharmaStoreWatcher() {
  yield takeEvery(GET_PHARMA_STORE_LIST, getPharmaStoreListWorker);
  yield takeEvery(GET_UPDATE_ORDER_STATUS, updateOrderStatusWorker);
}
