//Verify Pickup Code
export const START_VERIFY_PICKUP_CODE = "START_VERIFY_PICKUP_CODE";
export const SUCCESS_VERIFY_PICKUP_CODE = "SUCCESS_VERIFY_PICKUP_CODE";
export const ERROR_VERIFY_PICKUP_CODE = "ERROR_VERIFY_PICKUP_CODE";

//Pharmacy Details
export const START_CREATE_PHARMACY_DETAILS = "START_CREATE_PHARMACY_DETAILS";
export const SUCCESS_CREATE_PHARMACY_DETAILS =
  "SUCCESS_CREATE_PHARMACY_DETAILS";
export const ERROR_CREATE_PHARMACY_DETAILS = "ERROR_CREATE_PHARMACY_DETAILS";
