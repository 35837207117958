import { combineReducers } from "redux";
import { configReducer } from "./ConfigReducer";
import AuthReducers from "./AuthReducers";
import UserReducers from "./UserReducers";
import DoctorReducers from "./DoctorsReducer";
import DashboardReducers from "./DashboardReducer";
import StaffMemberReducers from "./StaffMemberReducer";
import PatientManagementReducers from "./PatientManagementReducers";
import PrescriptionReducers from "./PrescriptionReducers";
import PatientViewReducers from "./PatientViewReducer";
import PharmaStoreReducers from "./PharmaStoreReducer";
import VerifyPickupCodeReducers from "./VerifyPrescriptionReducer";

export default combineReducers({
  config: configReducer,
  auth: AuthReducers,
  user: UserReducers,
  doctors: DoctorReducers,
  dashboard: DashboardReducers,
  staffMember: StaffMemberReducers,
  patient: PatientManagementReducers,
  prescription: PrescriptionReducers,
  patientView: PatientViewReducers,
  pharmaStore: PharmaStoreReducers,
  verifyPickupCode: VerifyPickupCodeReducers,
  // Sample
  // auth: bindReducer(AuthReducers, { key: "auth" }),
});
