import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Box, CircularProgress } from "@mui/material";

const useStyle = makeStyles((theme) => ({
  cSSubmitBtn: {
    marginLeft: "3px !important",
    textAlign: "center",
    "&>button": {
      marginTop: "141px !important",
      marginBottom: "2px !important",
      borderRadius: "12px !important",
      width: "auto",
      padding: "28px 145px !important",
      [theme.breakpoints.down("1601")]: {
        padding: "25px 117px !important",
      },
      [theme.breakpoints.down("1000")]: {
        padding: "28px 136px !important",
      },
      [theme.breakpoints.down("462")]: {
        padding: "28px 125px !important",
      },
      [theme.breakpoints.down("440")]: {
        padding: "28px 115px !important",
      },
      [theme.breakpoints.down("420")]: {
        padding: "28px 105px !important",
      },
      [theme.breakpoints.down("400")]: {
        padding: "28px 95px !important",
      },
      [theme.breakpoints.down("380")]: {
        padding: "28px 85px !important",
      },
      [theme.breakpoints.down("360")]: {
        padding: "28px 75px !important",
      },
      [theme.breakpoints.down("340")]: {
        padding: "28px 65px !important",
      },
      [theme.breakpoints.down("320")]: {
        padding: "28px 55px !important",
      },
    },
  },
}));

export default function AuthButton({
  children,
  disabled = false,
  loading = false,
  startIcon,
}) {
  const classes = useStyle();

  return (
    <Box className={classes.cSSubmitBtn}>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        loading={loading}
        disabled={disabled}
        startIcon={loading ? null : startIcon}
      >
        {loading ? <CircularProgress color="white" size="20px" /> : children}
      </Button>
    </Box>
  );
}
