import React from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  loader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    left: "0%",
    right: 0,
  },
}));

export default function PageLoading({ height }) {
  const classes = useStyles();

  return (
    <Box className={classes.loader} height={height}>
      <CircularProgress
        className={classes.progress}
        color="primary"
        disableShrink
        size={80}
        thickness={2}
      />
    </Box>
  );
}
