import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  START_VERIFY_PICKUP_CODE,
  SUCCESS_VERIFY_PICKUP_CODE,
  ERROR_VERIFY_PICKUP_CODE,
  START_CREATE_PHARMACY_DETAILS,
  SUCCESS_CREATE_PHARMACY_DETAILS,
  ERROR_CREATE_PHARMACY_DETAILS,
} from "../Action/VerifyPrescriptionAction";
import {
  verifyPickupOptionAPI,
  pharmacyDetailsAPI,
} from "../Api/VerifyPrescriptionApis";
import { ADD_NEW_NOTIFICATION } from "../Action/ConfigAction";

function* verifyPickupCodeWorker({ formData }) {
  try {
    const res = yield call(verifyPickupOptionAPI, formData);
    if (res.success) {
      yield put({
        type: SUCCESS_VERIFY_PICKUP_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Pickup code verify successfully!",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_VERIFY_PICKUP_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* pharmacyDetailsWorker({ formData }) {
  try {
    const res = yield call(pharmacyDetailsAPI, formData);
    if (res.success) {
      yield put({
        type: SUCCESS_CREATE_PHARMACY_DETAILS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Pharmacy details added successfully!",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_CREATE_PHARMACY_DETAILS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

export function* VerifyPickupCodeWatcher() {
  yield takeEvery(START_VERIFY_PICKUP_CODE, verifyPickupCodeWorker);
  yield takeEvery(START_CREATE_PHARMACY_DETAILS, pharmacyDetailsWorker);
}
