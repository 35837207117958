import { ReactComponent as HomeIcon } from "../Assets/SideBarIcons/layout-grid.svg";
import { ReactComponent as DoctorIcon } from "../Assets/SideBarIcons/DoctorIcon.svg";
import { ReactComponent as StaffIcon } from "../Assets/SideBarIcons/StaffIcon.svg";
import { ReactComponent as DoctorDashboardIcon } from "../Assets/DashboardIcons/DoctorIcon.svg";
import { ReactComponent as StaffDashboardIcon } from "../Assets/DashboardIcons/StaffIcon.svg";
import { ReactComponent as PatientIcon } from "../Assets/SideBarIcons/PatientIcon.svg";
import { ReactComponent as PrescriptionIcon } from "../Assets/SideBarIcons/PrescriptionIcon.svg";
import { ReactComponent as PatientIconDashboard } from "../Assets/DashboardIcons/PatientIconDashboard.svg";
import BackgroundIcon from "../Assets/DashboardIcons/BackgroundIcon.png";
import { ReactComponent as RejectIcon } from "../Assets/DashboardIcons/RejectIcon.svg";
import BackgroundIconOne from "../Assets/DashboardIcons/BackgroundIconOne.png";
import BackgroundIconPatient from "../Assets/DashboardIcons/BackgroundIconPatient.png";
import { ReactComponent as PharmaStoreIcon } from "../Assets/SideBarIcons/PharmaStoreIcon.svg";
import BackgroundIconPrescriptionFour from "../Assets/DashboardIcons/BackgroundIconPrescriptionFour.png";

const ROLES = {
  CLINIC_OWNER: "clinic_owner",
  DOCTOR: "doctor",
  STAFF: "staff",
  PHARMASTORE: "pharma_store",
};

const ROLE_NAMES = {
  [ROLES.CLINIC_OWNER]: "Clinic Owner",
  [ROLES.DOCTOR]: "Doctor",
  [ROLES.STAFF]: "Staff",
  [ROLES.PHARMASTORE]: "Pharma Store",
};

const sidebarMenuItems = [
  {
    icon: HomeIcon,
    value: "Dashboard",
    key: "dashboard",
    url: "/dashboard",
    accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
  },
  {
    icon: DoctorIcon,
    value: "Doctors",
    key: "doctors",
    url: "/doctors",
    accesible_roles: [ROLES.CLINIC_OWNER],
  },
  {
    icon: StaffIcon,
    value: "Staff Members",
    key: "staff_member",
    url: "/staff_members",
    accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR],
  },
  {
    icon: PatientIcon,
    value: "Patients",
    key: "patients",
    url: "/patients",
    accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
  },
  {
    icon: PrescriptionIcon,
    value: "Prescriptions",
    key: "prescriptions",
    url: "/prescriptions",
    accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
  },
  {
    icon: PharmaStoreIcon,
    value: "Orders",
    key: "pharma_store",
    url: "/pharma_store",
    accesible_roles: [ROLES.PHARMASTORE],
  },
];

/** DashBoard Box List */
const dashboardWidget = {
  doctors_list: {
    icon: DoctorDashboardIcon,
    background: BackgroundIcon,
    accesible_roles: [ROLES.CLINIC_OWNER],
    label: "Doctors",
  },
  staff_list: {
    icon: StaffDashboardIcon,
    background: BackgroundIconOne,
    accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR],
    label: "Staff Members",
  },
  patient_management_list: {
    icon: PatientIconDashboard,
    background: BackgroundIconPatient,
    accesible_roles: [ROLES.CLINIC_OWNER, ROLES.DOCTOR, ROLES.STAFF],
    label: "Patients",
  },
  rejected_orders_list: {
    icon: RejectIcon,
    background: BackgroundIconPrescriptionFour,
    accesible_roles: [ROLES.CLINIC_OWNER],
    label: "Rejected",
  },
};

const genderList = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

const medicationStatusList = [
  {
    label: "All Prescription Status",
    value: "all",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Declined",
    value: "declined",
  },
];

const orderStatusList = [
  {
    label: "All Order Status",
    value: "all",
  },
  {
    label: "Ordered",
    value: "ordered",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Dispatched",
    value: "dispatched",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
];

const TitleList = [
  {
    value: "dr",
    label: "Dr",
  },
  {
    value: "miss",
    label: "Miss",
  },
  {
    value: "mr",
    label: "Mr",
  },
];

export {
  sidebarMenuItems,
  dashboardWidget,
  genderList,
  medicationStatusList,
  ROLES,
  ROLE_NAMES,
  orderStatusList,
  TitleList,
};
